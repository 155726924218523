<template>
  <b-sidebar id="sidebar-sale-add-payment" sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right>
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add Payment
        </h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- Body -->
      <validation-observer ref="paymentRules">
        <b-form class="p-2" @submit.prevent>
          <!-- Invoice Balance -->

            <b-form-group label="Payment Account" label-for="payment-account">
            <validation-provider name="Payment Account" #default="{ errors }" rules="required">
              <v-select v-model="addPaymentData.paymentAccount" @input="selectLeftTotal" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" 
               :options="listActiveAccounts"  :selectable="option => option.active" 
               label="title" :reduce="(val) => val.title" placeholder="Select Payment Account" input-id="payment-account" :clearable="false" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Invoice Balance" label-for="invoice-balance">
            <cleave style="height:2.142rem" v-model="leftTotal" disabled class="form-control" :raw="true" :options="options.number" />
          </b-form-group>

          <!-- Payment Amount -->
          <b-form-group label="Payment Amount" label-for="payment-amount">
            <validation-provider name="Payment Amount" #default="{ errors }" rules="required">
              <cleave style="height:2.142rem" v-model="addPaymentData.paymentAmount" class="form-control" max="5" :raw="true" :options="options.number" @input="valueControl(addPaymentData.paymentAmount)" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Payment Date -->
          <b-form-group label="Payment Date" label-for="payment-date">
            <validation-provider name="Payment Date" #default="{ errors }" rules="required">
              <flat-pickr v-model="addPaymentData.paymentDate" :config="{ dateFormat: 'd-m-Y' }" class="form-control" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Payment Method" label-for="payment-method">
            <validation-provider name="Payment Method" #default="{ errors }" rules="required">
              <v-select v-model="addPaymentData.paymentMethod" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="paymentMethods" label="Payment Method" placeholder="Select Payment Method" input-id="payment-method" :clearable="false" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Internal Payment Note -->
          <b-form-group label="Internal Payment Note" label-for="internal-payment-note">
            <b-form-textarea id="internal-payment-note" v-model="addPaymentData.note" placeholder="Internal Payment Note" rows="5" trim />
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit" @click="save">
              Send
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" @click="hide">
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BButton } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import flatPickr from 'vue-flatpickr-component';
import vSelect from 'vue-select';
import store from '@/store';
import { ref, onUnmounted } from '@vue/composition-api';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import Cleave from 'vue-cleave-component';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, email } from '@validations';

export default {
  components: {
    ToastificationContent,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    Cleave,
    ValidationProvider,
    ValidationObserver,

    flatPickr,
    vSelect,
  },
  directives: {
    Ripple,
  },

  props: {
    refreshQuote: {},
    saleQuoteData: {
      type: Object,
      required: false,
    },

    formShow: {
      required: true,
    },
  },

  data() {
    // { key: 'quoteDate', show: true, label: 'Quote Date', class: 'text-center', sortable: false, class: 'text-center', thStyle: 'width: 10%' },

    return {
      required,
      email,
      leftTotal:0,
      addPaymentData: {
        paymentAccount:null,
        invoiceBalance: null,
        paymentAmount: null,
        paymentDate: null,
        paymentMethod: null,
        note: null,
      },
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },

        percent: {
          numeral: true,
          numeralPositiveOnly: true,
          blocks: [2],
          prefix: '% ',
          rawValueTrimPrefix: true,
          numeralIntegerScale: 2,
        },
      },

      tempStatus: 'PMA',
      tempInvoiceData: null,

      paymentMethods: ['Cash', 'Bank Transfer', 'Debit', 'Credit', 'Paypal'],
    };
  },

  methods: {
    save() {
      this.$refs.paymentRules.validate().then((success) => {
       

        if (success) {
          this.$emit('update:formShow', true);
          this.$root.$emit('bv::toggle::collapse', 'sidebar-sale-add-payment');

          this.addPaymentData.status = 'S';
          this.addPaymentData.processId = this.saleQuoteData.id;
          this.addPaymentData.invoiceBalance = this.leftTotal;
          this.tempInvoiceData = this.saleQuoteData;

          store
            .dispatch('sales/savePayment', this.addPaymentData)
            .then((response) => {
              this.tempInvoiceData.payments.push(response.data);

               switch (this.addPaymentData.paymentAccount) {
                  case 'Vehicle Sale Receivable':
                  this.saleQuoteData.saleReceivableDebitLeft = parseFloat(response.data.invoiceBalance - response.data.paymentAmount);

                    break;

                    case 'Vehicle Sales Credit Sale':
                  this.saleQuoteData.saleTradeCreditSaleLeft = parseFloat(response.data.invoiceBalance - response.data.paymentAmount);

                    break;
                    
                  case 'Accumulated GST Collected':
                  this.saleQuoteData.gstCollectedSaleDebitLeft = parseFloat(response.data.invoiceBalance - response.data.paymentAmount);

                    break;

                  case 'Accumulated PST Collected':
                  this.saleQuoteData.pstCollectedSaleDebitLeft = parseFloat(response.data.invoiceBalance - response.data.paymentAmount);

                    break;

                    case 'Vehicle Sales Deposit':
                    this.saleQuoteData.customerDepositCreditLeft = parseFloat(response.data.invoiceBalance - response.data.paymentAmount);


                    break;
                    case 'Total Sale Refundable':
                  this.saleQuoteData.totalSaleRefundableLeft = parseFloat(response.data.invoiceBalance - response.data.paymentAmount);

                    break;
                    case 'Lien Payout Receivable':
                  this.saleQuoteData.lienPayoutReceivableLeft = parseFloat(response.data.invoiceBalance - response.data.paymentAmount);

                    break;
                    case 'Payable Trade Credit':
                  this.saleQuoteData.payTradeCreditLeft = parseFloat(response.data.invoiceBalance - response.data.paymentAmount);

                    break;
                
                  default:
                    break;
                }

              this.addPaymentData = {
                invoiceBalance: null,
                paymentAmount: null,
                paymentDate: null,
                paymentMethod: null,
                paymentAccount: null,
                note: null,
                vendorReference: null,
              };

              this.leftTotal = 0;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Successful',
                  text: '✔️ Payment Successful',
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              });
              let currentBalance = this.saleQuoteData.saleReceivableDebitLeft 
              + this.saleQuoteData.saleTradeCreditSaleLeft 
              + this.saleQuoteData.gstCollectedSaleDebitLeft 
              + this.saleQuoteData.pstCollectedSaleDebitLeft 
              + this.saleQuoteData.customerDepositCreditLeft 
              + this.saleQuoteData.totalSaleRefundableLeft
              + this.saleQuoteData.lienPayoutReceivableLeft
              + this.saleQuoteData.payTradeCreditLeft;
              console.log(currentBalance)


              if (currentBalance > 0 ) {
                this.refreshQuote();

                this.$emit('update:formShow', false);
              } else if (currentBalance == 0) {
                var value = {
                  id: this.saleQuoteData.id,
                  value: 'PMA',
                  email: this.saleQuoteData.sellerIdentification.email,
                  updated_at: this.saleQuoteData.updated_at,
                };

                store
                  .dispatch('sales/updateQuoteStatus', value)
                  .then((response) => {
                    this.saleQuoteData.status = response.data.status;

                    this.refreshQuote();
                    this.$emit('update:saleQuoteData.payments', this.tempInvoiceData.payments);
                  })
                  .catch((error) => {
                    console.log(error);
                    this.$emit('update:formShow', false);
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: 'Something went wrong',
                        text: 'Please try again or report an issue to support',
                        icon: 'ThumbsDownIcon',
                        variant: 'danger',
                      },
                    });
                    // router.push({ name: 'quote-list' });
                  });
              } else {
                this.$emit('update:formShow', false);
              }
            })
            .catch((error) => {
              console.log(error);
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Something went wrong',
                  text: 'Please try again or report an issue to support',
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              });
              // router.push({ name: 'quote-list' });
            });
        }
      });
    },

    valueControl(val) {
      if (Number(val) > Number(this.leftTotal)) {
        this.addPaymentData.paymentAmount = parseFloat(this.leftTotal);
      }
    },
    selectLeftTotal(){

      switch (this.addPaymentData.paymentAccount) {


          case 'Vehicle Sale Receivable':
          this.leftTotal =this.saleQuoteData.saleReceivableDebitLeft;

            break;
            case 'Lien Payout Receivable':
          this.leftTotal =this.saleQuoteData.lienPayoutReceivableLeft;

            break;

            case 'Vehicle Sales Credit Sale':
            this.leftTotal =this.saleQuoteData.saleTradeCreditSaleLeft;

            break;
            
          case 'Accumulated GST Collected':
          this.leftTotal =this.saleQuoteData.gstCollectedSaleDebitLeft;

            break;

          case 'Accumulated PST Collected':
          this.leftTotal =this.saleQuoteData.pstCollectedSaleDebitLeft;

            break;

            case 'Vehicle Sales Deposit':
            this.leftTotal =this.saleQuoteData.customerDepositCreditLeft;


            break;
            case 'Total Sale Refundable':
            this.leftTotal =this.saleQuoteData.totalSaleRefundableLeft;
            break;

            case 'Payable Trade Credit':
            this.leftTotal =this.saleQuoteData.payTradeCreditLeft;
            break;


    
        default:
          break;
      }

      this.addPaymentData.paymentAmount = 0;


      

    },
  },
  setup() {
    const ACCOUNTING_APP_STORE_MODULE_NAME = 'sales';

    // Register module
    if (!store.hasModule(ACCOUNTING_APP_STORE_MODULE_NAME)) store.registerModule(ACCOUNTING_APP_STORE_MODULE_NAME, salesStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ACCOUNTING_APP_STORE_MODULE_NAME)) store.unregisterModule(ACCOUNTING_APP_STORE_MODULE_NAME);
    });

    return {};
  },
  created() {},
   
computed:{

listActiveAccounts() {
  let accounts = [];
  if(this.saleQuoteData.saleReceivableDebitLeft >0){
    accounts.push(
      {title:'Vehicle Sale Receivable',active:true}
    )
  }
  else{
    accounts.push(
      {title:'Vehicle Sale Receivable',active:false}
    )

  }
  if(this.saleQuoteData.saleTradeCreditSaleLeft >0){
    accounts.push(
      {title:'Vehicle Sales Credit Sale',active:true}
    )    }
  else{
    accounts.push(
      {title:'Vehicle Sales Credit Sale',active:false}
    )

  }

  if(this.saleQuoteData.gstCollectedSaleDebitLeft >0){
    accounts.push(
      {title:'Accumulated GST Collected',active:true}
    )
  }
  else{
    accounts.push(
      {title:'Accumulated GST Collected',active:false}
    )

  }
  if(this.saleQuoteData.pstCollectedSaleDebitLeft >0){
    accounts.push(
      {title:'Accumulated PST Collected',active:true}
    )
  }
  else{
    accounts.push(
      {title:'Accumulated PST Collected',active:false}
    )

  }
  if(this.saleQuoteData.lienPayoutReceivableLeft >0){
    accounts.push(
      {title:'Lien Payout Receivable',active:true}
    )
  }
  else{
    accounts.push(
      {title:'Lien Payout Receivable',active:false}
    )

  }

  if(this.saleQuoteData.customerDepositCreditLeft >0){
    accounts.push(
      {title:'Vehicle Sales Deposit',active:true}
    )
  }
  else{
    accounts.push(
      {title:'Vehicle Sales Deposit',active:false}
    )

  }
  if(this.saleQuoteData.totalSaleRefundableLeft >0){
    accounts.push(
      {title:'Total Sale Refundable',active:true}
    )
  }
  else{
    accounts.push(
      {title:'Total Sale Refundable',active:false}
    )

  }

  if(this.saleQuoteData.payTradeCreditLeft >0){
    accounts.push(
      {title:'Payable Trade Credit',active:true}
    )
  }
  else{
    accounts.push(
      {title:'Payable Trade Credit',active:false}
    )

  }


  return accounts;
}

}
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
