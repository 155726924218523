<template>
  <b-overlay :show="formShow" rounded="sm" no-fade>
    <b-row v-if="saleQuoteData == undefined || formShow == true">
      <b-col cols="12">
        <center>Loading...</center>
      </b-col>
    </b-row>

    <b-row v-else class="invoice-preview">
      <b-col cols="12" xl="10" md="9">
        <b-alert variant="danger" class="mb-1" :show="saleQuoteData.status == 'D'">
          <div class="alert-body">
            <span v-if="saleQuoteData.notes != null"><strong>Reason for rejection : </strong> {{ saleQuoteData.notes }}</span>
            <span v-else><strong>Reason for rejection : </strong> No note has been written!</span>
          </div>
        </b-alert>

        <b-card no-body class="invoice-preview-card table-responsive">
          <span style="padding:10px;"
            ><strong>Sale Quote Number :</strong> #{{ Number($route.params.id) }}
            <strong class="ml-5">Sale Quote Date :</strong>
            {{ saleQuoteData.quoteDate }}
          </span>

          <table class="table table-bordered">
            <thead class="thead-dark">
              <tr>
                <th scope="col" colspan="12">
                  Buyer Identification (The "Buyer")
                </th>
              </tr>
            </thead>
            <tbody class="text-center">
              <tr class="textvalue" v-if="saleQuoteData.purchaserCustomerType == 1 || saleQuoteData.purchaserCustomerType == null">
                <td colspan="2">
                  <span style="font-size:180%;" v-if="saleQuoteData.purchaserCustomerType == 1"> &#9745;</span>
                  <span style="font-size:180%;" v-else> &#9744;</span>
                  Individual
                </td>
                <td colspan="2">
                  <span style="font-size:180%;" v-if="saleQuoteData.purchaserCustomerType == 2"> &#9745;</span>
                  <span style="font-size:180%;" v-else> &#9744;</span>
                  Business
                </td>
                <td colspan="4" :class="saleQuoteData.purchaserCustomerName == null ? 'bg-light-danger' : ''">
                  {{ saleQuoteData.purchaserCustomerName }}
                </td>
                <td colspan="4">
                  {{ saleQuoteData.purchaserCustomerLastName }}
                </td>
              </tr>
              <tr class="title" v-if="saleQuoteData.purchaserCustomerType == 1 || saleQuoteData.purchaserCustomerType == null">
                <th colspan="4" class="noborderright">Seller Type</th>
                <th colspan="4" class="noborderright">
                  First Name / Business Name
                </th>
                <th colspan="4">Last Name / Operating Name</th>
              </tr>

              <tr class="textvalue" v-if="saleQuoteData.purchaserCustomerType == 2">
                <td colspan="2">
                  <span style="font-size:180%;" v-if="saleQuoteData.purchaserCustomerType == 1"> &#9745;</span>
                  <span style="font-size:180%;" v-else> &#9744;</span>
                  Individual
                </td>
                <td colspan="2">
                  <span style="font-size:180%;" v-if="saleQuoteData.purchaserCustomerType == 2"> &#9745;</span>
                  <span style="font-size:180%;" v-else> &#9744;</span>
                  Business
                </td>
                <td colspan="2" :class="saleQuoteData.purchaserCustomerName == null ? 'bg-light-danger' : ''">
                  {{ saleQuoteData.purchaserCustomerName }}
                </td>
                <td colspan="2">
                  {{ saleQuoteData.purchaserCustomerLastName }}
                </td>
                <td colspan="2" :class="saleQuoteData.purchaserCustomerContactPerson == null ? 'bg-light-danger' : ''">
                  {{ saleQuoteData.purchaserCustomerContactPerson }}
                </td>
                <td colspan="2">
                  {{ saleQuoteData.purchaserCustomerContactInfo }}
                </td>
              </tr>
              <tr class="title" v-if="saleQuoteData.purchaserCustomerType == 2">
                <th colspan="4" class="noborderright">Seller Type</th>
                <th colspan="2" class="noborderright">
                  First Name / Business Name
                </th>
                <th colspan="2" class="noborderright">
                  Last Name / Operating Name
                </th>
                <th colspan="2" class="noborderright">Contact Person</th>
                <th colspan="2">Contact Info</th>
              </tr>

              <tr class="textvalue">
                <td colspan="2">{{ saleQuoteData.purchaserCustomerLicense }}&nbsp;</td>
                <td colspan="2">
                  {{ saleQuoteData.purchaserCustomerGst }}
                </td>
                <td colspan="4" :class="saleQuoteData.purchaserCustomerPhone == null ? 'bg-light-danger' : ''">
                  {{ saleQuoteData.purchaserCustomerPhone }}
                </td>
                <td colspan="4" :class="saleQuoteData.purchaserCustomerEmail == null ? 'bg-light-danger' : ''">
                  {{ saleQuoteData.purchaserCustomerEmail }}
                </td>
              </tr>

              <tr class="title">
                <th colspan="2" class="noborderright">
                  Driver's License Number
                </th>
                <th colspan="2" class="noborderright">G.S.T Registration</th>
                <th colspan="4" class="noborderright">Phone Number</th>
                <th colspan="4">Email</th>
              </tr>

              <tr class="textvalue">
                <td colspan="2">{{ saleQuoteData.purchaserCustomerOtherId }}&nbsp;</td>
                <td colspan="2">
                  {{ saleQuoteData.purchaserCustomerPst }}
                </td>
                <td colspan="2">
                  {{ saleQuoteData.purchaserCustomerUnitNumber }}
                </td>
                <td colspan="6" :class="saleQuoteData.purchaserCustomerStreet == null ? 'bg-light-danger' : ''">
                  {{ saleQuoteData.purchaserCustomerStreet }}
                </td>
              </tr>

              <tr class="title">
                <th colspan="2" class="noborderright">Other ID Number</th>
                <th colspan="2" class="noborderright">P.S.T Registration</th>
                <th colspan="2" class="noborderright">Unit Number</th>
                <th colspan="6">Street Address</th>
              </tr>

              <tr class="textvalue">
                <td colspan="2">{{ saleQuoteData.purchaserCustomerDateOfBirth }}&nbsp;</td>
                <td colspan="2">
                  {{ saleQuoteData.purchaserCustomerDealer }}
                </td>
                <td colspan="2" :class="saleQuoteData.purchaserCustomerCity == null ? 'bg-light-danger' : ''">
                  {{ saleQuoteData.purchaserCustomerCity }}
                </td>
                <td colspan="2" :class="saleQuoteData.purchaserCustomerProvince == null ? 'bg-light-danger' : ''">
                  {{ saleQuoteData.purchaserCustomerProvince }}
                </td>
                <td colspan="2" :class="saleQuoteData.purchaserCustomerPostal == null ? 'bg-light-danger' : ''">
                  {{ saleQuoteData.purchaserCustomerPostal }}
                </td>
                <td colspan="2" :class="saleQuoteData.purchaserCustomerCountry == null ? 'bg-light-danger' : ''">
                  {{ saleQuoteData.purchaserCustomerCountry }}
                </td>
              </tr>

              <tr class="title">
                <th colspan="2" class="noborderright">Date of Birth</th>
                <th colspan="2" class="noborderright">Dealer Registration</th>
                <th colspan="2" class="noborderright">City</th>
                <th colspan="2" class="noborderright">Province</th>
                <th colspan="2" class="noborderright">Postal Code</th>
                <th colspan="2">Country</th>
              </tr>
            </tbody>
          </table>
        </b-card>

        <b-card v-if="saleQuoteData.coOwnerList.length == 0 && allShow" no-body class="invoice-preview-card table-responsive">
          <table class="table table-bordered">
            <thead class="thead-dark">
              <tr>
                <th scope="col" colspan="12">Co-Buyer Identification</th>
              </tr>
            </thead>
            <tbody class="text-center">
              <tr class="textvalue">
                <td colspan="2">
                  <span style="font-size:180%;"> &#9744;</span>
                  Individual
                </td>
                <td colspan="2">
                  <span style="font-size:180%;"> &#9744;</span>
                  Business
                </td>
                <td colspan="4"></td>
                <td colspan="4"></td>
              </tr>
              <tr class="title">
                <th colspan="4" class="noborderright">Seller Type</th>
                <th colspan="4" class="noborderright">
                  First Name / Business Name
                </th>
                <th colspan="4">Last Name / Operating Name</th>
              </tr>

              <tr class="textvalue">
                <td colspan="2">&nbsp;</td>
                <td colspan="2"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
              </tr>

              <tr class="title">
                <th colspan="2" class="noborderright">
                  Driver's License Number
                </th>
                <th colspan="2" class="noborderright">G.S.T Registration</th>
                <th colspan="4" class="noborderright">Phone Number</th>
                <th colspan="4">Email</th>
              </tr>

              <tr class="textvalue">
                <td colspan="2">&nbsp;</td>
                <td colspan="2"></td>
                <td colspan="2"></td>
                <td colspan="6"></td>
              </tr>

              <tr class="title">
                <th colspan="2" class="noborderright">Other ID Number</th>
                <th colspan="2" class="noborderright">P.S.T Registration</th>
                <th colspan="2" class="noborderright">Unit Number</th>
                <th colspan="6">Street Address</th>
              </tr>

              <tr class="textvalue">
                <td colspan="2">&nbsp;</td>
                <td colspan="2"></td>
                <td colspan="2"></td>
                <td colspan="2"></td>
                <td colspan="2"></td>
                <td colspan="2"></td>
              </tr>

              <tr class="title">
                <th colspan="2" class="noborderright">Date of Birth</th>
                <th colspan="2" class="noborderright">Dealer Registration</th>
                <th colspan="2" class="noborderright">City</th>
                <th colspan="2" class="noborderright">Province</th>
                <th colspan="2" class="noborderright">Postal Code</th>
                <th colspan="2">Country</th>
              </tr>
            </tbody>
          </table>
        </b-card>

        <b-card v-for="item in saleQuoteData.coOwnerList" :key="item.customerId" no-body class="invoice-preview-card table-responsive">
          <table class="table table-bordered">
            <thead class="thead-dark">
              <tr>
                <th scope="col" colspan="12">Co-Purchaser Information</th>
              </tr>
            </thead>
            <tbody class="text-center">
              <tr class="textvalue" v-if="item.sellerType == 1 || item.sellerType == null">
                <td colspan="2">
                  <span style="font-size:180%;" v-if="item.sellerType == 1"> &#9745;</span>
                  <span style="font-size:180%;" v-else> &#9744;</span>
                  Individual
                </td>
                <td colspan="2">
                  <span style="font-size:180%;" v-if="item.sellerType == 2"> &#9745;</span>
                  <span style="font-size:180%;" v-else> &#9744;</span>
                  Business
                </td>
                <td colspan="4">{{ item.firstName }}</td>
                <td colspan="4">{{ item.lastName }}</td>
              </tr>

              <tr class="textvalue" v-if="item.sellerType == 2">
                <td colspan="2">
                  <span style="font-size:180%;" v-if="item.sellerType == 1"> &#9745;</span>
                  <span style="font-size:180%;" v-else> &#9744;</span>
                  Individual
                </td>
                <td colspan="2">
                  <span style="font-size:180%;" v-if="item.sellerType == 2"> &#9745;</span>
                  <span style="font-size:180%;" v-else> &#9744;</span>
                  Business
                </td>
                <td colspan="2">{{ item.firstName }}</td>
                <td colspan="2">{{ item.lastName }}</td>
                <td colspan="2">{{ item.contactPerson }}</td>
                <td colspan="2">{{ item.contactInfo }}</td>
              </tr>

              <tr class="title" v-if="item.sellerType == 1 || item.sellerType == null">
                <th colspan="4" class="noborderright">Seller Type</th>
                <th colspan="4" class="noborderright">
                  First Name / Business Name
                </th>
                <th colspan="4">Last Name / Operating Name</th>
              </tr>

              <tr class="title" v-if="item.sellerType == 2">
                <th colspan="4" class="noborderright">Seller Type</th>
                <th colspan="2" class="noborderright">
                  First Name / Business Name
                </th>
                <th colspan="2" class="noborderright">
                  Last Name / Operating Name
                </th>
                <th colspan="2" class="noborderright">Contact Person</th>
                <th colspan="2">Contact Info</th>
              </tr>

              <tr class="textvalue">
                <td colspan="2">{{ item.driverLicenseNumber }}</td>
                <td colspan="2">{{ item.gst }}</td>
                <td colspan="4">{{ item.phoneNumber }}</td>
                <td colspan="4">{{ item.email }}</td>
              </tr>

              <tr class="title">
                <th colspan="2" class="noborderright">
                  Driver's License Number
                </th>
                <th colspan="2" class="noborderright">G.S.T Registration</th>
                <th colspan="4" class="noborderright">Phone Number</th>
                <th colspan="4">Email</th>
              </tr>

              <tr class="textvalue">
                <td colspan="2">{{ item.otherIdNumber }}</td>
                <td colspan="2">{{ item.pst }}</td>
                <td colspan="2">{{ item.unitNumber }}</td>
                <td colspan="6">{{ item.streetAddress }}</td>
              </tr>

              <tr class="title">
                <th colspan="2" class="noborderright">Other ID Number</th>
                <th colspan="2" class="noborderright">P.S.T Registration</th>
                <th colspan="2" class="noborderright">Unit Number</th>
                <th colspan="6">Street Address</th>
              </tr>

              <tr class="textvalue">
                <td colspan="2">{{ item.dateOfBirth }}</td>
                <td colspan="2">{{ item.dealer }}</td>
                <td colspan="2">{{ item.city }}</td>
                <td colspan="2">{{ item.provence }}</td>
                <td colspan="2">{{ item.postalCode }}</td>
                <td colspan="2">{{ item.country }}</td>
              </tr>

              <tr class="title">
                <th colspan="2" class="noborderright">Date of Birth</th>
                <th colspan="2" class="noborderright">Dealer Registration</th>
                <th colspan="2" class="noborderright">City</th>
                <th colspan="2" class="noborderright">Province</th>
                <th colspan="2" class="noborderright">Postal Code</th>
                <th colspan="2">Country</th>
              </tr>
            </tbody>
          </table>
        </b-card>

        <b-row>
          <b-col cols="12" md="5" xl="5">
            <b-card no-body class="invoice-preview-card table-responsive">
              <table class="table table-bordered">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col" colspan="12">
                      Description of Vehicle (The "Vehicle")
                    </th>
                  </tr>
                </thead>
                <tbody class="text-center">
                  <tr class="textvalue">
                    <td colspan="6" :class="saleQuoteData.vehicleCondition == null ? 'bg-light-danger' : ''">
                      {{ saleQuoteData.vehicleCondition ? saleQuoteData.vehicleCondition : '&nbsp;' }}
                    </td>
                    <td colspan="6" :class="saleQuoteData.vehicleMake == null ? 'bg-light-danger' : ''">
                      {{ saleQuoteData.vehicleMake ? saleQuoteData.vehicleMake : '&nbsp;' }}
                    </td>
                  </tr>

                  <tr class="title">
                    <th colspan="6" class="noborderright">Condition</th>
                    <th colspan="6">Make</th>
                  </tr>

                  <tr class="textvalue">
                    <td colspan="6">
                      {{ saleQuoteData.vehicleYear ? saleQuoteData.vehicleYear : '&nbsp;' }}
                    </td>
                    <td colspan="6">
                      {{ saleQuoteData.vehicleModel ? saleQuoteData.vehicleModel : '&nbsp;' }}
                    </td>
                  </tr>

                  <tr class="title">
                    <th colspan="6" class="noborderright">Model Year</th>
                    <th colspan="6">Model</th>
                  </tr>

                  <tr class="textvalue">
                    <td colspan="6">
                      {{ saleQuoteData.vehicleFuel ? saleQuoteData.vehicleFuel : '&nbsp;' }}
                    </td>
                    <td colspan="6">
                      {{ saleQuoteData.vehicleTrim ? saleQuoteData.vehicleTrim : '&nbsp;' }}
                    </td>
                  </tr>

                  <tr class="title">
                    <th colspan="6" class="noborderright">Fuel Type</th>
                    <th colspan="6">Trim</th>
                  </tr>

                  <tr class="textvalue">
                    <td colspan="6">
                      {{ saleQuoteData.vehicleTransmission ? saleQuoteData.vehicleTransmission : '&nbsp;' }}
                    </td>
                    <td colspan="6" :class="saleQuoteData.vehicleExteriorColor == null ? 'bg-light-danger' : ''">
                      {{ saleQuoteData.vehicleExteriorColor ? saleQuoteData.vehicleExteriorColor : '&nbsp;' }}
                    </td>
                  </tr>

                  <tr class="title">
                    <th colspan="6" class="noborderright">Transmission</th>
                    <th colspan="6">Exterior Colour</th>
                  </tr>

                  <tr class="textvalue">
                    <td colspan="6">
                      {{ saleQuoteData.vehicleDrivetrain ? saleQuoteData.vehicleDrivetrain : '&nbsp;' }}
                    </td>
                    <td colspan="6">
                      {{ saleQuoteData.vehicleInteriorColor ? saleQuoteData.vehicleInteriorColor : '&nbsp;' }}
                    </td>
                  </tr>

                  <tr class="title">
                    <th colspan="6" class="noborderright">Drivetrain</th>
                    <th colspan="6">Interior Colour</th>
                  </tr>

                  <tr class="textvalue">
                    <td colspan="4">
                      {{ saleQuoteData.vehicleBodyType ? saleQuoteData.vehicleBodyType : '&nbsp;' }}
                    </td>
                    <td colspan="4" :class="saleQuoteData.vehicleMileage == null ? 'bg-light-danger' : ''">
                      {{ saleQuoteData.vehicleMileage ? saleQuoteData.vehicleMileage : '&nbsp;' }}
                    </td>
                    <td colspan="4" :class="saleQuoteData.vehicleMileageUnit == null ? 'bg-light-danger' : ''">
                      {{ saleQuoteData.vehicleMileageUnit ? saleQuoteData.vehicleMileageUnit : '&nbsp;' }}
                    </td>
                  </tr>

                  <tr class="title">
                    <th colspan="4" class="noborderright">Body Type</th>
                    <th colspan="4" class="noborderright">Mileage</th>
                    <th colspan="4">Mileage Unit</th>
                  </tr>

                  <tr class="textvalue">
                    <td colspan="6">{{ saleQuoteData.vehicleStockNumber ? saleQuoteData.vehicleStockNumber : '&nbsp;' }}&nbsp;</td>
                    <td colspan="6" :class="saleQuoteData.vehicleVIN == null ? 'bg-light-danger' : ''">
                      {{ saleQuoteData.vehicleVIN ? saleQuoteData.vehicleVIN : '&nbsp;' }}
                      &nbsp;
                    </td>
                  </tr>

                  <tr class="title">
                    <th colspan="6" class="noborderright">Stock Number</th>
                    <th colspan="6">Vehicle Identification Number</th>
                  </tr>
                </tbody>
              </table>
            </b-card>
          </b-col>

          <b-col cols="12" md="7" xl="7">
            <b-card no-body class="invoice-preview-card table-responsive">
              <table class="table table-bordered">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col" colspan="10">Vehicle Declearation</th>
                    <th scope="col" colspan="1" class="text-center">YES</th>
                    <th scope="col" colspan="1" class="text-center">NO</th>
                  </tr>
                </thead>
                <tbody class="text-left">
                  <tr class="textlongvalue">
                    <td colspan="10">
                      1. Is the motor vehicle complies with the requirements of the Motor Vehicle Act? If no, the vehicle is sold for parts only or for purposes other than transportation, and this fact has been disclosed to the Purchaser.
                    </td>
                    <td colspan="1" style="height:5em" :class="saleQuoteData.vd1 == null ? 'bg-light-danger' : ''">
                      <span style="font-size:200%;" v-if="saleQuoteData.vd1 == 'true'">
                        &#x2714;
                      </span>
                    </td>
                    <td colspan="1" :class="saleQuoteData.vd1 == null ? 'bg-light-danger' : ''">
                      <span style="font-size:200%;" v-if="!saleQuoteData.vd1 == 'false'">
                        &#x2714;
                      </span>
                    </td>
                  </tr>

                  <tr class="textlongvalue">
                    <td colspan="10">
                      2. Has the motor vehicle ever been used as a taxi, police vehicle, emergency vehicle or used in organized racing?
                    </td>
                    <td colspan="1" style="height:5em" :class="saleQuoteData.vd2 == null ? 'bg-light-danger' : ''">
                      <span style="font-size:200%;" v-if="saleQuoteData.vd2 == 'true'">
                        &#x2714;
                      </span>
                    </td>
                    <td colspan="1" :class="saleQuoteData.vd2 == null ? 'bg-light-danger' : ''">
                      <span style="font-size:200%;" v-if="saleQuoteData.vd2 == 'false'"> &#x2714;</span>
                    </td>
                  </tr>

                  <tr class="textlongvalue">
                    <td colspan="10">
                      3. Has the motor vehicle ever been used as a lease or rental vehicle?
                    </td>
                    <td colspan="1" style="height:5em" :class="saleQuoteData.vd3 == null ? 'bg-light-danger' : ''">
                      <span style="font-size:200%;" v-if="saleQuoteData.vd3 == 'true'">
                        &#x2714;
                      </span>
                    </td>
                    <td colspan="1" :class="saleQuoteData.vd3 == null ? 'bg-light-danger' : ''">
                      <span style="font-size:200%;" v-if="saleQuoteData.vd3 == 'false'"> &#x2714;</span>
                    </td>
                  </tr>

                  <tr class="textlongvalue">
                    <td colspan="10" style="height:5em">
                      4. Has the motor vehicle ever sustained damage requiring repairs costing more than $2000.00?
                    </td>
                    <td colspan="1" :class="saleQuoteData.vd4 == null ? 'bg-light-danger' : ''">
                      <span style="font-size:200%;" v-if="saleQuoteData.vd4 == 'true'">
                        &#x2714;
                      </span>
                    </td>
                    <td colspan="1" :class="saleQuoteData.vd4 == null ? 'bg-light-danger' : ''">
                      <span style="font-size:200%;" v-if="saleQuoteData.vd4 == 'false'"> &#x2714;</span>
                    </td>
                  </tr>

                  <tr class="textlongvalue">
                    <td colspan="10" style="height:5em">
                      5. Has the motor vehicle ever been registered in any other jurisdiction other than British Columbia? If "Yes", in what jurisdiction(s) has the vehicle been registered:
                      <i>{{ saleQuoteData.vd1Text }}</i>
                    </td>
                    <td colspan="1" :class="saleQuoteData.vd5 == null ? 'bg-light-danger' : ''">
                      <span style="font-size:200%;" v-if="saleQuoteData.vd5 == 'true'">
                        &#x2714;
                      </span>
                    </td>
                    <td colspan="1" :class="saleQuoteData.vd5 == null ? 'bg-light-danger' : ''">
                      <span style="font-size:200%;" v-if="saleQuoteData.vd5 == 'false'"> &#x2714;</span>
                    </td>
                  </tr>

                  <tr class="textlongvalue">
                    <td colspan="10" style="height:5em">
                      6. Has the Vehicle been brought into British Columbia specifically for the purpose of resale?
                    </td>
                    <td colspan="1" :class="saleQuoteData.vd6 == null ? 'bg-light-danger' : ''">
                      <span style="font-size:200%;" v-if="saleQuoteData.vd6 == 'true'">
                        &#x2714;
                      </span>
                    </td>
                    <td colspan="1" :class="saleQuoteData.vd6 == null ? 'bg-light-danger' : ''">
                      <span style="font-size:200%;" v-if="saleQuoteData.vd6 == 'false'"> &#x2714;</span>
                    </td>
                  </tr>

                  <tr class="textlongvalue">
                    <td colspan="10" style="height:5em">
                      7. The odometer of the vehicle accurately records the ture distance traveled by the motor vehicle to the best of the dealer's knowledge and belief.
                    </td>
                    <td colspan="1" :class="saleQuoteData.vd7 == null ? 'bg-light-danger' : ''">
                      <span style="font-size:200%;" v-if="saleQuoteData.vd7 == 'true'">
                        &#x2714;
                      </span>
                    </td>
                    <td colspan="1" :class="saleQuoteData.vd7 == null ? 'bg-light-danger' : ''">
                      <span style="font-size:200%;" v-if="saleQuoteData.vd7 == 'false'"> &#x2714;</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-card>
          </b-col>
        </b-row>

        <b-row v-if="!isEmptyOrSpaces(saleQuoteData.vehicleDescription) || allShow">
          <b-col cols="12">
            <b-card no-body class="invoice-preview-card table-responsive">
              <table class="table table-bordered">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col" colspan="12">Listing Details</th>
                  </tr>
                </thead>
                <tbody class="text-center">
                  <tr class="title">
                    <td colspan="8" rowspan="4" style="width: 80%; height:250px; font-size:14px; text-align:left; vertical-align:top; white-space: pre-line">
                      {{ saleQuoteData.vehicleDescription ? saleQuoteData.vehicleDescription : '' }}
                    </td>
                    <td colspan="4" style="width: 20%; font-size:14px; text-align:center; vertical-align:middle">
                      --
                    </td>
                  </tr>
                  <tr>
                    <th style="height:10px; font-size:14px; text-align:center; vertical-align:middle">
                      Listing Currency
                    </th>
                  </tr>
                  <tr>
                    <td style=" font-size:14px; text-align:center; vertical-align:middle">
                      {{ saleQuoteData.vehicleListingPrice ? formatPrice(saleQuoteData.vehicleListingPrice) : '' }}
                    </td>
                  </tr>

                  <tr>
                    <th style="height:10px; font-size:14px; text-align:center; vertical-align:middle">
                      Listing Price
                    </th>
                  </tr>
                </tbody>
              </table>
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-card no-body class="invoice-preview-card table-responsive">
              <table class="table table-bordered">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col" colspan="12">Price Calculation</th>
                  </tr>
                </thead>
                <tbody class="text-center">
                  <tr class="title">
                    <td colspan="1" style="width:1%">
                      <span style="font-size:180%;" v-if="(saleQuoteData.priceContractType ? saleQuoteData.priceContractType : 0) == 1">&#x2611;</span>
                      <span v-else style="font-size:180%;">&#x2610;</span>
                    </td>
                    <td colspan="3" style="width:19%">Cash</td>
                    <td colspan="4" style="width:20%; border-bottom:hidden"></td>
                    <th colspan="2" style="width:25%" class="text-right">
                      Vehicle Listing Price
                    </th>
                    <td colspan="2" style="width:25%" v-if="saleQuoteData.get_inventory.listingPrice != null">
                      {{ formatPrice(saleQuoteData.get_inventory.listingPrice) }}
                    </td>
                    <td colspan="2" style="width:25%" v-else>
                      {{ formatPrice(0) }}
                    </td>
                  </tr>

                  <tr class="title">
                    <td colspan="1">
                      <span style="font-size:180%;" v-if="(saleQuoteData.priceContractType ? saleQuoteData.priceContractType : 0) == 2">&#x2611;</span>
                      <span v-else style="font-size:180%;">&#x2610;</span>
                    </td>
                    <td colspan="3">Finance</td>
                    <td colspan="4" style=" border-bottom:hidden"></td>
                    <td colspan="2" class="text-right">
                      Discount Rate / Amount
                    </td>
                    <td colspan="1">
                      {{ saleQuoteData.discountRate ? formatPrice(saleQuoteData.discountRate) + '%' : '0.00' }}
                    </td>
                    <td colspan="1">
                      {{ formatPrice(saleQuoteData.get_inventory.listingPrice - saleQuoteData.sellingPrice > 0 ? saleQuoteData.get_inventory.listingPrice - saleQuoteData.sellingPrice : 0.0) }}
                    </td>
                  </tr>

                  <tr class="title">
                    <th colspan="4">Contract Type</th>
                    <td colspan="4" style=" border-bottom:hidden"></td>
                    <td colspan="2" class="text-right">
                      Vehicle Selling Price
                    </td>
                    <td colspan="2">
                      {{ formatPrice(saleQuoteData.sellingPrice ? saleQuoteData.sellingPrice : 0) }}
                    </td>
                  </tr>

                  <tr class="title">
                    <td colspan="1" style="width:1%">
                      <span style="font-size:180%;"  v-if="(saleQuoteData.saleType ? saleQuoteData.saleType : 'Retail') == 'Retail'">&#x2611;</span>
                      <span v-else style="font-size:180%;">&#x2610;</span>
                    </td>
                    <td colspan="3" style="width:19%">Retail</td>
                    <td colspan="4" style=" border-bottom:hidden"></td>
                    <td colspan="2" class="text-right">
                      <i>Less Trade-in Allowance</i>
                    </td>
                    <td id="lessTradeAllowance" colspan="2">
                      {{ formatPrice(saleQuoteData.lessTradeAllowance ? saleQuoteData.lessTradeAllowance : 0, 2) }}
                    </td>
                  </tr>

                  <tr class="title">
                    <td colspan="1" style="width:1%">
                      <span style="font-size:180%;" v-if="(saleQuoteData.saleType ? saleQuoteData.saleType : 'Wholesale') == 'Wholesale'">&#x2611;</span>
                      <span v-else style="font-size:180%;">&#x2610;</span>
                    </td>
                    <td colspan="3" style="width:19%">Wholesale</td>
                    <td colspan="4" style=" border-bottom:hidden"></td>
                    <th id="tradeEquity" colspan="2" class="text-right">
                      Customer Trade-in Equity
                    </th>

                    <td colspan="2" v-if="saleQuoteData.lessTradeAllowance > saleQuoteData.sellingPrice">
                      {{ formatPrice(saleQuoteData.customerTradeEquity, 2) }}
                      <!-- {{ customerTradeEquity }} -- {{ saleQuoteData.deductionCredit }} --
                      {{ saleQuoteData.lessAdditionalCredit }} -->
                      <!-- return this.lessTrade - this.saleQuoteData.sellingPrice - this.saleQuoteData.deductionCredit - this.saleQuoteData.lessAdditionalCredit; -->
                    </td>
                    <td colspan="2" v-else>
                      {{ formatPrice(0) }}
                    </td>
                  </tr>

                  <tr class="title">
                    <th colspan="4">Sale Type</th>
                    <td colspan="4" style=" border-bottom:hidden"></td>
                    <td colspan="2" class="text-right">
                      Vehicle Price Difference
                    </td>

                    <td id="priceDifference" colspan="2">
                      {{ formatPrice(saleQuoteData.priceDifference) }}
                    </td>
                  </tr>

                  <tr class="title">
                    <th colspan="4" style="border-left:hidden; border-right:hidden; border-bottom:hidden;"></th>
                    <td colspan="4" style=" border-bottom:hidden"></td>
                    <td colspan="2" class="text-right">
                      G.S.T Rate & Amount on Vehicle
                    </td>
                    <td colspan="1">
                      {{ saleQuoteData.gstRateVehicle ? formatPrice(saleQuoteData.gstRateVehicle ? saleQuoteData.gstRateVehicle : 0) + '%' : '0.00' }}
                    </td>
                    <td id="gstAmountVehicle" colspan="1">
                      {{ formatPrice(saleQuoteData.gstAmount) }}
                    </td>
                  </tr>

                  <tr class="title">
                    <th colspan="4" style="border-left:hidden; border-right:hidden; border-bottom:hidden;"></th>
                    <td colspan="4" style=" border-bottom:hidden"></td>
                    <td colspan="2" class="text-right">
                      P.S.T Rate & Amount on Vehicle
                    </td>
                    <td colspan="1">
                      {{ saleQuoteData.pstRateVehicle ? formatPrice(saleQuoteData.pstRateVehicle ? saleQuoteData.pstRateVehicle : 0) + '%' : '0.00' }}
                    </td>
                    <td id="pstAmountVehicle" colspan="1">
                      {{ formatPrice(saleQuoteData.pstAmount) }}
                    </td>
                  </tr>

                  <tr class="title">
                    <th colspan="4" style="border-left:hidden; border-right:hidden; border-bottom:hidden;"></th>
                    <td colspan="4" style=" border-bottom:hidden"></td>
                    <th colspan="2" class="text-right">
                      Total Vehicle Sale Amount
                    </th>

                    <td id="totalSaleAmount" colspan="2" v-if="saleQuoteData.customerTradeEquity <= 0">
                      {{ formatPrice(saleQuoteData.totalSaleAmount) }}
                    </td>
                    <td colspan="2" v-else>{{ formatPrice(0) }}</td>
                  </tr>
                  <br /><br />

                  <tr class="title  thead-dark" v-if="saleQuoteData.get_additional.length > 0 || allShow">
                    <th colspan="4" style="width:20%;">
                      Additional Purchase Options
                    </th>
                    <th colspan="2" style="width:15%;">Option Price</th>
                    <th colspan="1" style="width:10%;">G.S.T Rate</th>
                    <th colspan="1" style="width:15%;">G.S.T Amount</th>
                    <th colspan="1" style="width:10%;">P.S.T Rate</th>
                    <th colspan="1" style="width:15%;">P.S.T Amount</th>
                    <th colspan="2" style="width:15%;">Option Sub-Total</th>
                  </tr>

                  <tr class="title" v-for="item in saleQuoteData.get_additional" :key="item.id">
                    <td colspan="4">{{ item.title }}</td>
                    <td colspan="2">
                      {{ item.price ? formatPrice(item.price) : '' }}
                    </td>
                    <td colspan="1">{{ item.gst ? formatPrice(item.gst) : '' }}%</td>
                    <td colspan="1">
                      {{ item.price ? formatPrice((item.price * (item.gst ? item.gst : 0)) / 100) : '' }}
                    </td>
                    <td colspan="1">{{ item.pst ? formatPrice(item.pst) : '' }}%</td>
                    <td colspan="1">
                      {{ item.price ? formatPrice((item.price * (item.pst ? item.pst : 0)) / 100) : '' }}
                    </td>
                    <td colspan="2">
                      {{ formatPrice((item.price ? item.price : 0) + (item.price * (item.gst ? item.gst : 0)) / 100 + (item.price * (item.pst ? item.pst : 0)) / 100) }}
                    </td>
                  </tr>

                  <tr class="title" v-for="item in 3" :key="item.id">
                    <th colspan="4" v-if="saleQuoteData.get_additional.length > 0 || allShow">
                      &nbsp;
                    </th>
                    <th colspan="2" v-if="saleQuoteData.get_additional.length > 0 || allShow">
                      &nbsp;
                    </th>
                    <td colspan="1" v-if="saleQuoteData.get_additional.length > 0 || allShow">
                      &nbsp;
                    </td>
                    <td colspan="1" v-if="saleQuoteData.get_additional.length > 0 || allShow">
                      &nbsp;
                    </td>
                    <td colspan="1" v-if="saleQuoteData.get_additional.length > 0 || allShow">
                      &nbsp;
                    </td>
                    <td colspan="1" v-if="saleQuoteData.get_additional.length > 0 || allShow">
                      &nbsp;
                    </td>
                    <td colspan="2" v-if="saleQuoteData.get_additional.length > 0 || allShow">
                      &nbsp;
                    </td>
                  </tr>

                  <br /><br />
                  <tr class="title">
                    <td colspan="10" class="text-right" style="border-top:hidden; border-left:hidden;">
                      Additional Options Price
                    </td>
                    <td id="additionalOptionsPrice" colspan="2">
                      {{ formatPrice(saleQuoteData.optionAmountTotal) }}
                    </td>
                  </tr>

                  <tr class="title">
                    <th colspan="10" class="text-right" style="border-top:hidden; border-left:hidden;">
                      <i>Deduction by Trade-in credit</i>
                    </th>
                    <td id="deductionByCredit" colspan="2">
                      {{ formatPrice(saleQuoteData.deductionCredit ? saleQuoteData.deductionCredit : 0) }}
                    </td>
                  </tr>

                  <tr class="title">
                    <td colspan="10" class="text-right" style="border-top:hidden; border-left:hidden;">
                      <i>Sub total Additional Options</i>
                    </td>
                    <td id="subTotalAdditional" colspan="2">
                      {{ formatPrice(saleQuoteData.optionsSubTotal) }}
                    </td>
                  </tr>

                  <tr class="title">
                    <td colspan="10" class="text-right" style="border-top:hidden; border-left:hidden;">
                      G.S.T. on Additional Options
                    </td>
                    <td id="gstAdditional" colspan="2">
                      {{ formatPrice(saleQuoteData.optionsGst) }}
                    </td>
                  </tr>

                  <tr class="title">
                    <td colspan="10" class="text-right" style="border-top:hidden; border-left:hidden;">
                      P.S.T. on Additional Options
                    </td>
                    <td id="pstAdditional" colspan="2">
                      {{ formatPrice(saleQuoteData.optionsPst) }}
                    </td>
                  </tr>

                  <tr class="title">
                    <th colspan="10" class="text-right" style="border-top:hidden; border-left:hidden;">
                      Total Additional Options
                    </th>
                    <td id="totalAdditional" colspan="2">
                      {{ formatPrice(saleQuoteData.optionAmountTotal) }}
                    </td>
                  </tr>

                  <tr class="title">
                    <td colspan="10" class="text-right" style="border-top:hidden; border-left:hidden;">
                      Plus Total Lien Payout on Trade-ins
                    </td>
                    <td id="totalLienTrades" colspan="2">
                      {{ formatPrice(saleQuoteData.lienPayoutTrades) }}
                    </td>
                  </tr>

                  <tr class="title">
                    <td colspan="10" class="text-right" style="border-top:hidden; border-left:hidden;">
                      Total Sale Contract Amount
                    </td>
                    <td id="contractAmount" colspan="2">
                      {{ formatPrice(saleQuoteData.totalContractAmount) }}
                    </td>
                  </tr>

                  <tr class="title">
                    <td colspan="10" class="text-right" style="border-top:hidden; border-left:hidden;">
                      Less Deposit Payable by Customer
                    </td>
                    <td id="lessDeposit" colspan="2">
                      {{ formatPrice(saleQuoteData.lessDepositPayable ? saleQuoteData.lessDepositPayable : 0) }}
                    </td>
                  </tr>

                  <tr class="title">
                    <td colspan="10" class="text-right" style="border-top:hidden; border-left:hidden;">
                      Less additional Trade-in credit as payment
                    </td>
                    <td id="lessAdditionalTrade" colspan="2">
                      {{ formatPrice(saleQuoteData.lessAdditionalCredit ? saleQuoteData.lessAdditionalCredit : 0) }}
                    </td>
                  </tr>

                  <tr class="title">
                    <td colspan="10" class="text-right" style="border-top:hidden; border-left:hidden;">
                      Balance Due on Delivery
                    </td>
                    <td id="balanceDue" colspan="2" v-if="saleQuoteData.balanceDueDeliveryPreview > 0">
                      {{ formatPrice(saleQuoteData.balanceDueDeliveryPreview) }}
                    </td>

                    <td colspan="2" v-else>0.00</td>
                  </tr>

                  <tr class="title" v-if="saleQuoteData.payableToCustomerValue > 0">
                    <td colspan="10" class="text-right text-danger" style="border-top:hidden; border-left:hidden;">
                      Payable To Customer
                    </td>
                    <td id="payableToCustomer" colspan="2" class="text-danger" v-if="saleQuoteData.payableToCustomerValue > 0">
                      {{ formatPrice(saleQuoteData.payableToCustomerValue) }}
                    </td>

                    <!-- <td id="payableToCustomer" colspan="2" class="text-danger" v-else>
                      {{ formatPrice((saleQuoteData.dueOnDelivery + Number(saleQuoteData.payTradeCredit)) * -1) }}
                    </td> -->
                  </tr>

                  <tr class="title" v-if="saleQuoteData.payTradeCredit > 0">
                    <td colspan="10" class="text-right text-danger" style="border-top:hidden; border-left:hidden;">
                      Payable Trade Credit
                    </td>
                    <td id="payableTradeCredit" colspan="2" class="text-danger">
                      {{ formatPrice(saleQuoteData.payTradeCredit) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-card>
          </b-col>
        </b-row>

        <!-- {{ saleQuoteData.trades[0].quoteId }} -->
        <b-row v-if="saleQuoteData.trades.length > 0">
          <b-col cols="12">
            <table class="table table-bordered">
              <thead class="thead-dark">
                <tr>
                  <th scope="col" colspan="12">Trade-Ins</th>
                </tr>
              </thead>
            </table>

            <b-row v-for="item in saleQuoteData.trades" :key="item.id">
              <b-col cols="12" md="5" xl="5">
                <b-card no-body class="invoice-preview-card table-responsive">
                  <table class="table table-bordered">
                    <thead class="thead-dark">
                      <tr>
                        <th scope="col" colspan="12">
                          Description of Trade-in (The "Trade-in")
                        </th>
                      </tr>
                    </thead>
                    <tbody class="text-center">
                      <tr class="textvalue">
                        <td colspan="6" :class="item.vehicleCondition == null ? 'bg-light-danger' : ''">
                          {{ item ? item.vehicleCondition : '&nbsp;' }}
                        </td>
                        <td colspan="6" :class="item.vehicleMake == null ? 'bg-light-danger' : ''">
                          {{ item.vehicleMake ? item.vehicleMake : '&nbsp;' }}
                        </td>
                      </tr>

                      <tr class="title">
                        <th colspan="6" class="noborderright">Condition</th>
                        <th colspan="6">Make</th>
                      </tr>

                      <tr class="textvalue">
                        <td colspan="6">
                          {{ item.vehicleYear ? item.vehicleYear : '&nbsp;' }}
                        </td>
                        <td colspan="6">
                          {{ item.vehicleModel ? item.vehicleModel : '&nbsp;' }}
                        </td>
                      </tr>

                      <tr class="title">
                        <th colspan="6" class="noborderright">Model Year</th>
                        <th colspan="6">Model</th>
                      </tr>

                      <tr class="textvalue">
                        <td colspan="6">
                          {{ item.vehicleFuel ? item.vehicleFuel : '&nbsp;' }}
                        </td>
                        <td colspan="6">
                          {{ item.vehicleTrim ? item.vehicleTrim : '&nbsp;' }}
                        </td>
                      </tr>

                      <tr class="title">
                        <th colspan="6" class="noborderright">Fuel Type</th>
                        <th colspan="6">Trim</th>
                      </tr>

                      <tr class="textvalue">
                        <td colspan="6">
                          {{ item.vehicleTransmission ? item.vehicleTransmission : '&nbsp;' }}
                        </td>
                        <td colspan="6" :class="item.vehicleExteriorColor == null ? 'bg-light-danger' : ''">
                          {{ item.vehicleExteriorColor ? item.vehicleExteriorColor : '&nbsp;' }}
                        </td>
                      </tr>

                      <tr class="title">
                        <th colspan="6" class="noborderright">Transmission</th>
                        <th colspan="6">Exterior Colour</th>
                      </tr>

                      <tr class="textvalue">
                        <td colspan="6">
                          {{ item.vehicleDrivetrain ? item.vehicleDrivetrain : '&nbsp;' }}
                        </td>
                        <td colspan="6">
                          {{ item.vehicleInteriorColor ? item.vehicleInteriorColor : '&nbsp;' }}
                        </td>
                      </tr>

                      <tr class="title">
                        <th colspan="6" class="noborderright">Drivetrain</th>
                        <th colspan="6">Interior Colour</th>
                      </tr>

                      <tr class="textvalue">
                        <td colspan="4">
                          {{ item.vehicleBodyType ? item.vehicleBodyType : '&nbsp;' }}
                        </td>
                        <td colspan="4" :class="item.vehicleMileage == null ? 'bg-light-danger' : ''">
                          {{ item ? item.vehicleMileage : '&nbsp;' }}
                        </td>
                        <td colspan="4" :class="item.vehicleMileageUnit == null ? 'bg-light-danger' : ''">
                          {{ item ? item.vehicleMileageUnit : '&nbsp;' }}
                        </td>
                      </tr>

                      <tr class="title">
                        <th colspan="4" class="noborderright">Body Type</th>
                        <th colspan="4" class="noborderright">Mileage</th>
                        <th colspan="4">Mileage Unit</th>
                      </tr>

                      <tr class="textvalue">
                        <td colspan="6">{{ item ? item.vehicleStockNumber : '&nbsp;' }}&nbsp;</td>
                        <td colspan="6" :class="item.vehicleVIN == null ? 'bg-light-danger' : ''">
                          {{ item ? item.vehicleVIN : '&nbsp;' }}
                          &nbsp;
                        </td>
                      </tr>

                      <tr class="title">
                        <th colspan="6" class="noborderright">Stock Number</th>
                        <th colspan="6">Vehicle Identification Number</th>
                      </tr>
                    </tbody>
                  </table>
                </b-card>
              </b-col>
              <b-col cols="12" md="7" xl="7">
                <b-card no-body class="invoice-preview-card table-responsive">
                  <table class="table table-bordered">
                    <thead class="thead-dark">
                      <tr>
                        <th scope="col" colspan="10">Trade-in Declearation</th>
                        <th scope="col" colspan="1" class="text-center">YES</th>
                        <th scope="col" colspan="1" class="text-center">NO</th>
                      </tr>
                    </thead>
                    <tbody class="text-left">
                      <tr class="textlongvalue">
                        <td colspan="10">
                          1. Is the motor vehicle complies with the requirements of the Motor Vehicle Act? If no, the vehicle is sold for parts only or for purposes other than transportation, and this fact has been disclosed to the Purchaser.
                        </td>
                        <td colspan="1" style="height:5em" :class="item.vd1 == null ? 'bg-light-danger' : ''">
                          <span style="font-size:200%;" v-if="item.vd1 == 'true'">
                            &#x2714;
                          </span>
                        </td>
                        <td colspan="1" :class="item.vd1 == null ? 'bg-light-danger' : ''">
                          <span style="font-size:200%;" v-if="!item.vd1 == 'false'">
                            &#x2714;
                          </span>
                        </td>
                      </tr>

                      <tr class="textlongvalue">
                        <td colspan="10">
                          2. Has the motor vehicle ever been used as a taxi, police vehicle, emergency vehicle or used in organized racing?
                        </td>
                        <td colspan="1" style="height:5em" :class="item.vd2 == null ? 'bg-light-danger' : ''">
                          <span style="font-size:200%;" v-if="item.vd2 == 'true'">
                            &#x2714;
                          </span>
                        </td>
                        <td colspan="1" :class="item.vd2 == null ? 'bg-light-danger' : ''">
                          <span style="font-size:200%;" v-if="item.vd2 == 'false'"> &#x2714;</span>
                        </td>
                      </tr>

                      <tr class="textlongvalue">
                        <td colspan="10">
                          3. Has the motor vehicle ever been used as a lease or rental vehicle?
                        </td>
                        <td colspan="1" style="height:5em" :class="item.vd3 == null ? 'bg-light-danger' : ''">
                          <span style="font-size:200%;" v-if="item.vd3 == 'true'">
                            &#x2714;
                          </span>
                        </td>
                        <td colspan="1" :class="item.vd3 == null ? 'bg-light-danger' : ''">
                          <span style="font-size:200%;" v-if="item.vd3 == 'false'"> &#x2714;</span>
                        </td>
                      </tr>

                      <tr class="textlongvalue">
                        <td colspan="10" style="height:5em">
                          4. Has the motor vehicle ever sustained damage requiring repairs costing more than $2000.00?
                        </td>
                        <td colspan="1" :class="item.vd4 == null ? 'bg-light-danger' : ''">
                          <span style="font-size:200%;" v-if="item.vd4 == 'true'">
                            &#x2714;
                          </span>
                        </td>
                        <td colspan="1" :class="item.vd4 == null ? 'bg-light-danger' : ''">
                          <span style="font-size:200%;" v-if="item.vd4 == 'false'"> &#x2714;</span>
                        </td>
                      </tr>

                      <tr class="textlongvalue">
                        <td colspan="10" style="height:5em">
                          5. Has the motor vehicle ever been registered in any other jurisdiction other than British Columbia? If "Yes", in what jurisdiction(s) has the vehicle been registered: <i>{{ item.vd1Text }}</i>
                        </td>
                        <td colspan="1" :class="item.vd5 == null ? 'bg-light-danger' : ''">
                          <span style="font-size:200%;" v-if="item.vd5 == 'true'">
                            &#x2714;
                          </span>
                        </td>
                        <td colspan="1" :class="item.vd5 == null ? 'bg-light-danger' : ''">
                          <span style="font-size:200%;" v-if="item.vd5 == 'false'"> &#x2714;</span>
                        </td>
                      </tr>

                      <tr class="textlongvalue">
                        <td colspan="10" style="height:5em">
                          6. Has the Vehicle been brought into British Columbia specifically for the purpose of resale?
                        </td>
                        <td colspan="1" :class="item.vd6 == null ? 'bg-light-danger' : ''">
                          <span style="font-size:200%;" v-if="item.vd6 == 'true'">
                            &#x2714;
                          </span>
                        </td>
                        <td colspan="1" :class="item.vd6 == null ? 'bg-light-danger' : ''">
                          <span style="font-size:200%;" v-if="item.vd6 == 'false'"> &#x2714;</span>
                        </td>
                      </tr>

                      <tr class="textlongvalue">
                        <td colspan="10" style="height:5em">
                          7. The odometer of the vehicle accurately records the ture distance traveled by the motor vehicle to the best of the dealer's knowledge and belief.
                        </td>
                        <td colspan="1" :class="item.vd7 == null ? 'bg-light-danger' : ''">
                          <span style="font-size:200%;" v-if="item.vd7 == 'true'">
                            &#x2714;
                          </span>
                        </td>
                        <td colspan="1" :class="item.vd7 == null ? 'bg-light-danger' : ''">
                          <span style="font-size:200%;" v-if="item.vd7 == 'false'"> &#x2714;</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-card>
              </b-col>

              <b-col cols="12">
                <b-card no-body class="invoice-preview-card table-responsive">
                  <table class="table table-bordered">
                    <thead class="thead-dark">
                      <tr>
                        <th scope="col" colspan="12">Trade-in Appraisal</th>
                      </tr>
                    </thead>

                    <tbody class="text-center">
                      <tr>
                        <td colspan="1" style="width: 10%;">
                          <span style="font-size:180%;" v-if="item.vehicleTitleType == 1"> &#9745;</span>
                          <span style="font-size:180%;" v-else> &#9744;</span>
                        </td>
                        <td colspan="2" style="width: 25%;">Clean Owned</td>
                        <td colspan="9" style="width: 65%;" rowspan="9">
                          {{ item.appraisalNotes }}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <span style="font-size:180%;" v-if="item.vehicleTitleType == 2"> &#9745;</span>
                          <span style="font-size:180%;" v-else> &#9744;</span>
                        </td>
                        <td>Owned with Lien Payout</td>
                      </tr>

                      <tr>
                        <td>
                          <span style="font-size:180%;" v-if="item.vehicleTitleType == 3"> &#9745;</span>
                          <span style="font-size:180%;" v-else> &#9744;</span>
                        </td>
                        <td>Leased</td>
                      </tr>

                      <tr>
                        <th colspan="2">Vehicle Title Type</th>
                      </tr>

                      <tr>
                        <td colspan="2">
                          {{ formatPrice(item.tradeInCreditUsed ? item.tradeInCreditUsed : 0, 2) }}
                        </td>
                      </tr>

                      <tr>
                        <th colspan="2">Trade-in Credit Used</th>
                      </tr>

                      <tr>
                        <td colspan="2">
                          {{ formatPrice(item.tradeInCreditBalanceToBeAdded ? item.tradeInCreditBalanceToBeAdded : 0, 2) }}
                        </td>
                      </tr>

                      <tr>
                        <th colspan="2">Trade-in Credit Balance</th>
                      </tr>

                      <tr>
                        <td colspan="2">
                          {{ formatPrice(item.appraisedVehiclePrice ? item.appraisedVehiclePrice : 0, 2) }}
                        </td>
                      </tr>

                      <tr>
                        <th colspan="3">Appraised Vehicle Price</th>
                        <th colspan="9">Appraisal Notes</th>
                      </tr>
                    </tbody>
                  </table>
                </b-card>
              </b-col>

              <b-col cols="12" md="4" xl="4">
                <b-card no-body class="invoice-preview-card table-responsive">
                  <table class="table table-bordered">
                    <thead class="thead-dark">
                      <tr>
                        <th scope="col" colspan="12">
                          Lien Payout On Trade-in
                        </th>
                      </tr>
                    </thead>
                    <tbody class="text-center">
                      <tr class="title">
                        <td colspan="1" style="width:10%">
                          <span v-if="item.payoutType == 1" style="font-size:180%;">&#x2611;</span>
                          <span v-else style="font-size:180%;">&#x2610;</span>
                        </td>
                        <td colspan="11" style="width:90%">Finance Payout</td>
                      </tr>

                      <tr class="title">
                        <td colspan="1">
                          <span v-if="item.payoutType == 2" style="font-size:180%;">&#x2611;</span>
                          <span v-else style="font-size:180%;">&#x2610;</span>
                        </td>
                        <td colspan="11">Lease Payout</td>
                      </tr>

                      <tr class="title">
                        <th colspan="12">Payout Type</th>
                      </tr>

                      <tr class="textvalue">
                        <td colspan="12">
                          {{ item.beforeTax ? formatPrice(item.beforeTax) : '0.00' }}
                        </td>
                      </tr>

                      <tr class="title">
                        <th colspan="12">Net Payout Before Tax</th>
                      </tr>

                      <tr class="textvalue">
                        <td colspan="6">
                          {{ item.gstRate ? formatPrice(item.gstRate) + '%' : '0.00' }}
                        </td>
                        <td colspan="6">
                          {{ item.beforeTax ? formatPrice((item.beforeTax * (item.gstRate ? item.gstRate : 0)) / 100) : '0.00' }}
                        </td>
                      </tr>

                      <tr class="title">
                        <th colspan="6" style="width:40%">G.S.T Rate</th>
                        <th colspan="6" style="width:60%">G.S.T Amount</th>
                      </tr>

                      <tr class="textvalue">
                        <td colspan="12">
                          {{ item.beforeTax ? formatPrice((item.beforeTax * (item.gstRate ? item.gstRate : 0)) / 100 + item.beforeTax) : '&nbsp;' }}
                        </td>
                      </tr>

                      <tr class="title">
                        <th colspan="12">Total Payout Amount</th>
                      </tr>

                      <tr class="textvalue">
                        <td colspan="12">
                          {{ item.unitlDate ? item.unitlDate : '&nbsp;' }}
                        </td>
                      </tr>

                      <tr class="title">
                        <th colspan="12">Good Until Date</th>
                      </tr>
                    </tbody>
                  </table>
                </b-card>
              </b-col>

              <b-col cols="12" md="8" xl="8">
                <b-card no-body class="invoice-preview-card table-responsive">
                  <table class="table table-bordered">
                    <thead class="thead-dark">
                      <tr>
                        <th scope="col" colspan="12">
                          Lien Holder Information
                        </th>
                      </tr>
                    </thead>
                    <tbody class="text-center">
                      <tr class="title">
                        <td colspan="1" style="width:3%">
                          <span style="font-size:180%;" v-if="(item.lienHolderList ? item.lienHolderList.sellerType : 0) == 1">&#x2611;</span>
                          <span v-else style="font-size:180%;">&#x2610;</span>
                        </td>
                        <td colspan="3" style="width:21%">Individual</td>

                        <td colspan="4" rowspan="2">
                          {{ item.lienHolderList ? item.lienHolderList.firstName : '&nbsp;' }}
                        </td>
                        <td colspan="4" rowspan="2">
                          {{ item.lienHolderList ? item.lienHolderList.lastName : '&nbsp;' }}
                        </td>
                      </tr>

                      <tr class="title">
                        <td colspan="1">
                          <span style="font-size:180%;" v-if="(item.lienHolderList ? item.lienHolderList.sellerType : 0) == 2">&#x2611;</span>
                          <span v-else style="font-size:180%;">&#x2610;</span>
                        </td>
                        <td colspan="3">Business</td>
                      </tr>
                      <tr class="title">
                        <th colspan="4" class="noborderright" style="width:24%">
                          Seller Type
                        </th>
                        <th colspan="4" class="noborderright" style="width:38%">
                          First Name / Business Name
                        </th>
                        <th colspan="4" style="width:38%">
                          Last Name / Operating Name
                        </th>
                      </tr>

                      <tr class="textvalue">
                        <td colspan="4">
                          {{ item.lienHolderList ? item.lienHolderList.driverLicenseNumber : '&nbsp;' }}
                        </td>
                        <td colspan="4">
                          {{ item.lienHolderList ? item.lienHolderList.phoneNumber : '&nbsp;' }}
                        </td>
                        <td colspan="4">
                          {{ item.lienHolderList ? item.lienHolderList.email : '&nbsp;' }}
                        </td>
                      </tr>

                      <tr class="title">
                        <th colspan="4" class="noborderright">
                          Driver's License Number
                        </th>
                        <th colspan="4" class="noborderright">Phone Number</th>
                        <th colspan="4">Email</th>
                      </tr>

                      <tr class="textvalue">
                        <td colspan="4">
                          {{ item.lienHolderList ? item.lienHolderList.gst : '&nbsp;' }}
                        </td>
                        <td colspan="4">
                          {{ item.lienHolderList ? item.lienHolderList.unitNumber : '&nbsp;' }}
                        </td>
                        <td colspan="4" rowspan="3" class="text-left align-top">
                          {{ item.lienHolderList ? item.lienHolderList.streetAddress : '&nbsp;' }}
                        </td>
                      </tr>

                      <tr class="title">
                        <th colspan="4" class="noborderright">
                          G.S.T Registration
                        </th>
                        <th colspan="4">Unit Number</th>
                      </tr>

                      <tr class="textvalue">
                        <td colspan="4">
                          {{ item.lienHolderList ? item.lienHolderList.pst : '&nbsp;' }}
                        </td>
                        <td colspan="4">
                          {{ item.lienHolderList ? item.lienHolderList.city : '&nbsp;' }}
                        </td>
                      </tr>

                      <tr class="title">
                        <th colspan="4" class="noborderright">
                          P.S.T Registration
                        </th>
                        <th colspan="4" class="noborderright">City</th>
                        <th colspan="4">Street Address</th>
                      </tr>

                      <tr class="textvalue">
                        <td colspan="3">
                          {{ item.lienHolderList ? item.lienHolderList.dealer : '&nbsp;' }}
                        </td>
                        <td colspan="3">
                          {{ item.lienHolderList ? item.lienHolderList.provence : '&nbsp;' }}
                        </td>
                        <td colspan="3">
                          {{ item.lienHolderList ? item.lienHolderList.postalCode : '&nbsp;' }}
                        </td>
                        <td colspan="3">
                          {{ item.lienHolderList ? item.lienHolderList.country : '&nbsp;' }}
                        </td>
                      </tr>

                      <tr class="title">
                        <th colspan="3" class="noborderright">
                          Dealer Registration
                        </th>
                        <th colspan="3" class="noborderright">Province</th>
                        <th colspan="3" class="noborderright">Postal Code</th>
                        <th colspan="3">Country</th>
                      </tr>
                    </tbody>
                  </table>
                </b-card>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <!-- v-if="!isEmptyOrSpaces(saleQuoteData.comments) || allShow" -->
        <!-- Buraya gelecek  -->

        <b-row v-if="!isEmptyOrSpaces(saleQuoteData.comments) || allShow">
          <b-col cols="12">
            <b-card no-body class="invoice-preview-card table-responsive">
              <table class="table table-bordered">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col" colspan="12">
                      Comments, Promises, Restrictions, Limitations and Conditions
                    </th>
                  </tr>
                </thead>
                <tbody class="text-center">
                  <tr class="title">
                    <td colspan="12" style="height:250px; font-size:14px; text-align:left; vertical-align:top">
                      {{ saleQuoteData.comments }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" xl="12" md="8">
            <b-card no-body>
              <table class="table table-bordered">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col" colspan="12">Payment Details</th>
                  </tr>
                </thead>
              </table>

              <b-table striped hover :bordered="true" class="text-center mt-2 pr-2 pl-2" selectable responsive :small="true" head-variant="dark" show-empty empty-text="No matching records found" :fields="paymentFields" :items="saleQuoteData.payments">
                <template #cell(id)="row">
                  <strong class="text-primary">#{{ Number(row.item.id) }}</strong>
                </template>

                <template #cell(invoiceBalance)="row">
                  <span>{{ row.item.invoiceBalance ? formatPrice(row.item.invoiceBalance) : 'N/A' }}</span>
                </template>

                <template #cell(paymentAmount)="row">
                  <span>{{ row.item.paymentAmount ? formatPrice(row.item.paymentAmount) : 'N/A' }}</span>
                </template>

                <template #cell(email)="row">
                  <span>{{ row.item.email ? row.item.email : 'N/A' }}</span>
                </template>
              </b-table>
            </b-card>
          </b-col>
        </b-row>
      </b-col>

      <b-col cols="12" xl="2" md="3" class="invoice-actions ">
        <b-card>
          <!-- Button: Send Invoice -->

          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" @click="allShow = !allShow" variant="primary" class="mb-75" block> <span v-if="!allShow">Show</span> <span v-else>Hide</span> Empty Fields </b-button>
          <hr />
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-if="saleQuoteData.status == 'A' && userRole != 'supermanager'" v-b-toggle.sidebar-send-invoice variant="primary" @click="statusChange('B')" class="mb-75" block>
            Send to Manager
          </b-button>

          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-show="$Can('sale_quote_approve')" v-if="saleQuoteData.status == 'A' || saleQuoteData.status == 'B'" @click="statusChange('C')" v-b-toggle.sidebar-send-invoice variant="success" class="mb-75" block>
            Approve the Quote
          </b-button>

          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-show="$Can('sale_quote_refuse')" v-if="saleQuoteData.status == 'A' || saleQuoteData.status == 'B'" @click="statusChange('D')" v-b-toggle.sidebar-send-invoice variant="danger" class="mb-75" block>
            Refuse the Quote
          </b-button>

          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-show="$Can('sale_quote_mark_lost')" v-if="saleQuoteData.status == 'D'" @click="statusChange('P')" v-b-toggle.sidebar-send-invoice variant="danger" class="mb-75" block>
            Mark as Lost
          </b-button>

          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-show="$Can('sale_quote_revise')" v-if="saleQuoteData.status == 'D' || saleQuoteData.status == 'C'" @click="statusChange('R')" v-b-toggle.sidebar-send-invoice variant="warning" class="mb-75" block>
            Revise the Quote
          </b-button>

          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-show="$Can('sale_quote_send_client')" v-if="saleQuoteData.status == 'C'" @click="statusChange('E')" variant="info" class="mb-75" block>
            Send to Client
          </b-button>

          <!-- Sent To Slient  -->
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-show="$Can('sale_quote_mark_won')" v-if="saleQuoteData.status == 'E'" @click="statusChange('PA')" v-b-toggle.sidebar-send-invoice variant="success" class="mb-75" block>
            Mark as Won
          </b-button>
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-show="$Can('sale_quote_revise')" v-if="saleQuoteData.status == 'E'" @click="statusChange('R')" v-b-toggle.sidebar-send-invoice variant="warning" class="mb-75" block>
            Revise the Quote
          </b-button>
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-show="$Can('sale_quote_mark_lost')" v-if="saleQuoteData.status == 'E'" @click="statusChange('P')" v-b-toggle.sidebar-send-invoice variant="danger" class="mb-75" block>
            Mark as Lost
          </b-button>
          <!-- Finish  -->

          <!-- Client Approved -->
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-show="$Can('sale_quote_bypass_client')" v-if="saleQuoteData.status == 'C'" @click="statusChange('PA')" v-b-toggle.sidebar-send-invoice variant="success" class="mb-75" block>
            Mark as Won
          </b-button>

          <!-- Finish  -->

          <!-- Sale Payment Recii -->

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-show="$Can('mark_payment_received')"
            v-if="(saleQuoteData.get_inventory.status == 'PC' || saleQuoteData.get_inventory.status == 'PB') && saleQuoteData.payment == 'no' && saleQuoteData.status != 'J' && saleQuoteData.status != 'P'"
            v-b-toggle.sidebar-sale-add-payment
            variant="success"
            class="mb-75"
            block
          >
            Payment Received
          </b-button>

          <!-- Finish -->

          <!-- Finaly -->

          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-show="$Can('sale_quote_mark_complete')" v-if="saleQuoteData.get_inventory.status == 'PD' && saleQuoteData.status != 'P' && saleQuoteData.status != 'J'" @click="statusChange('MarkComplete')" variant="success" class="mb-75" block>
            Complete
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-show="$Can('sale_quote_cancel') && this.where != 'archive-sales-preview' && saleQuoteData.excelImport != true"
            v-if="
              (saleQuoteData.get_inventory.status == 'PE' || saleQuoteData.get_inventory.status == 'PD' || saleQuoteData.get_inventory.status == 'PK' || saleQuoteData.get_inventory.status == 'PB' || saleQuoteData.get_inventory.status == 'PC') &&
                saleQuoteData.status != 'P' &&
                saleQuoteData.status != 'J'
            "
            @click="statusChange('MarkCancel')"
            variant="danger"
            class="mb-75"
            block
          >
            Cancel
          </b-button>

          <!-- Button: DOwnload -->
          <b-overlay :show="download" spinner-variant="primary" spinner-type="grow" spinner-small>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" @click="pdfDownload($route.params.id)" variant="secondary" class="mb-75" block>
              Save as Pdf
            </b-button>
          </b-overlay>

          <!-- Button: Edit -->
          <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" v-show="$Can('sale_quote_edit')" v-if="saleQuoteData.status == 'A' || saleQuoteData.status == 'B'" variant="outline-primary" class="mb-75" block :to="{ name: 'sales-edit', params: { id: $route.params.id } }">
            Edit
          </b-button>
        </b-card>

        <b-card>
          <!-- Go to vehicle -->
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-show="saleQuoteData.archive != 'yes'" @click="goToVehicle(saleQuoteData.inventoryId)" variant="info" class="mb-75" block>
            Go to Vehicle
          </b-button>

          <!-- trades list -->
          <h6 class="text-center mt-1" style="text-decoration: underline;" v-if="saleQuoteData.trades.length > 0">
            Trades<b>{{ ' (' + saleQuoteData.trades.length + ')' }}</b>
          </h6>
          <!-- <b-list-group flush>
          <b-list-group-item style="cursor:pointer;font-size: 0.8rem;" :key="trade.inventoryId" v-for="trade in saleQuoteData.trades" @click="goToVehicle(trade.inventoryId)" class="text-center">{{trade.get_inventory.get_model_year.value + "-" + trade.get_inventory.get_make.value + " " + trade.get_inventory.get_model.value}}</b-list-group-item>
      </b-list-group> -->
          <b-button
            pill
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            block
            size="sm"
            variant="outline-primary"
            :key="trade.inventoryId"
            v-for="trade in saleQuoteData.trades"
            @click="goToVehicle(trade.oldInventoryId ? trade.oldInventoryId : trade.inventoryId)"
            class="text-center mt-1"
            v-show="saleQuoteData.trades.length > 0"
            >{{ trade.get_inventory.get_model_year.value + '-' + trade.get_inventory.get_make.value + ' ' + trade.get_inventory.get_model.value }}
          </b-button>
        </b-card>
        <!-- v-show="where == 'sales-preview'" -->
        <b-card>
          <!-- <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-b-toggle.sidebar-send-purchase @click="addNewFile()" size="sm" block variant="primary" v-if="userRole != 'supermanager'" v-show="purchaseQuoteData.status == 'A' || purchaseQuoteData.status == 'B'"> Add New File </b-button> -->
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-b-toggle.sidebar-send-purchase @click="addNewFile()" size="sm" block variant="primary">
            Add New File
          </b-button>

          <!-- user suggestion  -->
          <div v-for="(data, index) in files" :key="index" class="d-flex justify-content-start align-items-center" :class="index == 0 ? 'mt-2' : 'mt-1'">
            <b-img src="@/assets/images/file.png" @click="fileEdit(data)" class="mr-50 " style="cursor:pointer" width="25px" />
            <div class="user-page-info pr-1" style="width: 100%">
              <small @click="fileEdit(data)" style="cursor:pointer; font-size:.8em">{{ data.fileName }} </small>
            </div>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="primary" target="_blank" :href="fileURL + data.fileLink" style="cursor:alias; padding: 1% 1%; margin-right:2px" class="btn-icon ml-auto" size="sm">
              <feather-icon icon="DownloadIcon" />
            </b-button>

            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="danger" @click="deleteFile(data)" v-if="userRole != 'supermanager'" v-show="saleQuoteData.status == 'A' || saleQuoteData.status == 'B'" class="btn-icon ml-auto" style="padding: 1% 1%;" size="sm">
              <feather-icon icon="XIcon" />
            </b-button>

            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="danger" @click="deleteFile(data)" v-else class="btn-icon ml-auto" style="padding: 1% 1%;" size="sm">
              <feather-icon icon="XIcon" />
            </b-button>
          </div>

          <div v-if="files.length == 0" class="d-flex justify-content-center align-items-center mt-1 mb-1">
            No Files
          </div>
        </b-card>

        <app-timeline class="mt-2">
          <app-timeline-item v-for="item in saleQuoteData.get_timelines" :key="item.id" :title="item.title" :subtitle="'By ' + item.username" :icon="varicon(item.status)" :time="dateFormat(item.created_at)" :variant="varyant(item.status)" />
        </app-timeline>
      </b-col>
    </b-row>

    <b-modal id="modal-file" cancel-variant="outline-secondary" ok-title="Save File" @ok="handleOk" @show="resetModal" @hidden="resetModal" cancel-title="Close" :no-close-on-backdrop="true" centered title="Add New File">
      <b-form ref="fileRules">
        <b-form-group :state="nameState" invalid-feedback="File Name is required">
          <label for="fileName">File Name:</label>
          <b-form-input id="fileName" type="text" :state="nameState" v-model="modalFileName" required placeholder="File Name" />
        </b-form-group>
        <b-form-group :state="fileState" invalid-feedback="File is required">
          <b-form-file @input="fileSelected" type="file" :state="fileState" required placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." />
        </b-form-group>
      </b-form>

      <b-col v-if="percentCompleted != 0 && percentCompleted != 100" class="mt-2">
        <b-progress key="info" animated :value="percentCompleted" show-progress variant="dark" class="'progress-bar-info'" />
      </b-col>
    </b-modal>

    <b-modal id="modal-file-update" cancel-variant="outline-secondary" ok-title="Change File" @ok="handleUpdate" cancel-title="Close" :no-close-on-backdrop="true" centered title="Add New File">
      <b-form ref="fileRules">
        <b-form-group :state="nameState" invalid-feedback="File Name is required">
          <label for="fileName">File Name:</label>
          <b-form-input id="fileName" type="text" :state="nameState" v-model="modalFileName" required placeholder="File Name" />
        </b-form-group>
      </b-form>
      <b-col v-if="percentCompleted != 0 && percentCompleted != 100" class="mt-2">
        <b-progress key="info" animated :value="percentCompleted" show-progress variant="dark" class="'progress-bar-info'" />
      </b-col>
    </b-modal>
    <sidebar-sale-quote-add-payment :refreshQuote="refreshQuote" :formShow.sync="formShow" :saleQuoteData.sync="saleQuoteData" />
  </b-overlay>
</template>

<script>
import { VBTooltip, BImg, BMediaAside, BContainer, BBadge, BOverlay, BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle, BFormCheckbox, BListGroup, BListGroupItem } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import router from '@/router';
import Logo from '@core/layouts/components/QuoteLogo.vue';
import store from '@/store';
import salesStoreModule from '../salesStoreModule';
import { ref, onUnmounted, reactive } from '@vue/composition-api';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue';
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue';
import moment from 'moment';
import axiosIns from '@/libs/axios';
import SidebarSaleQuoteAddPayment from './SidebarSaleQuoteAddPayment.vue';

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },

  components: {
    VBTooltip,
    BImg,
    BMediaAside,
    BContainer,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    BFormCheckbox,
    BOverlay,
    BBadge,
    BListGroup,
    BListGroupItem,
    AppTimeline,
    AppTimelineItem,
    Logo,
    SidebarSaleQuoteAddPayment,
  },

  setup() {
    const SALE_APP_STORE_MODULE_NAME = 'sales';
    // Register module
    if (!store.hasModule(SALE_APP_STORE_MODULE_NAME)) store.registerModule(SALE_APP_STORE_MODULE_NAME, salesStoreModule);
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SALE_APP_STORE_MODULE_NAME)) store.unregisterModule(SALE_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    return {
      where: router.currentRoute.name,
      files: [],
      userRole: JSON.parse(localStorage.getItem('userData')).role,
      formShow: false,
      saleQuoteData: { leftTotal: null },
      notApprove: '',
      download: false,
      baseURL: store.state.app.baseURL,
      fileURL: store.state.app.fileURL,
      allShow: false,
      lessTrade: 0,

      modalFileName: null,
      modalFile: null,
      percentCompleted: 0,

      paymentFields: [
        { key: 'id', label: 'PAYMENT ID', class: 'text-center', thStyle: 'width: 10%' },
        { key: 'paymentDate', label: 'DATE', class: 'text-center', thStyle: 'width: 10%' },
        { key: 'invoiceBalance', label: 'BALANCE', class: 'text-center', thStyle: 'width: 10%' },
        { key: 'paymentAmount', label: 'PAYMENT AMOUNT', class: 'text-center', thStyle: 'width: 10%' },
        { key: 'paymentAccount', label: 'PAYMENT ACCOUNT', class: 'text-center', thStyle: 'width: 25%' },
        { key: 'paymentMethod', label: 'PAYMENT METHOD', class: 'text-center', thStyle: 'width: 10%' },
        { key: 'note', label: 'NOTES', class: 'text-center', thStyle: 'width: 25%' },

      ],
    };
  },

  created() {
    this.refreshQuote();
  },

  methods: {
    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(',', '.');
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    },

    refreshQuote() {
      if (this.where == 'sales-preview') {
        this.getByIdQuote('getPreviewQuoteById');
      } else {
        this.getByIdQuote('getArchivePreviewQuoteById');
      }
    },

    fileEdit(data) {
      this.modalId = data.id;
      if (this.userRole == 'supermanager') {
        this.modalFileName = data.fileName;

        this.$bvModal.show('modal-file-update');
      } else if (this.saleQuoteData.status == 'A' || this.saleQuoteData.status == 'B') {
        this.modalFileName = data.fileName;
        this.$bvModal.show('modal-file-update');
      }
    },

    deleteFile(data) {
      this.$swal({
        title: 'Are you sure?',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.formShow = true;

          axiosIns
            .delete(`deleteSaleFile/${data.id}`)
            .then((res) => {
              var tempList = [];
              this.files.forEach((element) => {
                if (element.id != data.id) {
                  tempList.push(element);
                }
              });

              this.files = tempList;
              this.formShow = false;
            })
            .catch((error) => {
              console.log('error delete ', error);
            });
        }
      });
    },

    isEmptyOrSpaces(str) {
      return str === null || str == '' || str == ' ';
    },

    addNewFile() {
      this.$bvModal.show('modal-file');
    },

    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      if (this.percentCompleted == 100) {
        var data = {
          quoteId: this.saleQuoteData.id,
          fileName: this.modalFileName,
          fileLink: this.modalFile,
        };
        // Trigger submit handler
        this.modalOk(data);
      }
    },

    handleUpdate(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.modalUpdate();
    },

    resetModal() {
      this.modalFileName = null;
      this.modalFile = null;
    },

    modalOk(data) {
      if (!this.checkFormValidity()) {
        return;
      }

      this.$nextTick(() => {
        this.$bvModal.hide('modal-file');
      });

      this.formShow = true;

      axiosIns
        .post('saveSaleFile', data)
        .then((response) => {
          this.files.push({
            id: response.data.id,
            fileName: response.data.fileName,
            fileLink: response.data.fileLink,
          });

          this.modalFileName = null;
          this.modalFile = null;

          this.formShow = false;
        })
        .catch((error) => {
          this.formShow = false;
          console.log(error);
        });
    },

    modalUpdate(val) {
      if (!this.checkFormValidity()) {
        return;
      }

      this.$nextTick(() => {
        this.$bvModal.hide('modal-file-update');
      });

      this.formShow = true;

      var data = {
        id: this.modalId,
        fileName: this.modalFileName,
        fileLink: this.modalFile,
      };

      axiosIns
        .put('updateSaleFile', data)
        .then((response) => {
          this.modalFileName = null;
          this.modalFile = null;

          this.files.forEach((file) => {
            if (file.id == this.modalId) {
              file.fileName = response.data.fileName;
            }
          });

          this.formShow = false;
        })
        .catch((error) => {
          this.formShow = false;
          console.log(error);
        });
    },

    fileSelected(event) {
      //  this.formShow = true;
      let formData = new FormData();
      formData.append('file', event);

      const config = {
        onUploadProgress: function(progressEvent) {
          this.percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        }.bind(this),
      };

      axiosIns
        .post('uploadSaleFile', formData, config)
        .then((response) => {
          this.modalFile = response.data;

          // this.tempImage = null;
          // this.selectedImage = null;
          this.formShow = false;
        })
        .catch((error) => {
          this.formShow = false;
          console.log(error);
        });
    },

    checkFormValidity() {
      const nameValid = this.$refs.fileRules.checkValidity();
      const fileValid = this.$refs.fileRules.checkValidity();

      return fileValid && nameValid;
    },

    varyant(val) {
      if (val == 'A') {
        return 'success';
      } else if (val == 'B') {
        return 'info';
      } else if (val == 'C') {
        return 'success';
      } else if (val == 'D') {
        return '';
      } else if (val == 'E') {
        return 'warning';
      } else if (val == 'I') {
        return 'success';
      } else if (val == 'J') {
        return 'danger';
      } else if (val == 'PA') {
        return 'info';
      } else if (val == 'P') {
        return 'danger';
      } else if (val == 'G') {
        return 'success';
      } else if (val == 'H') {
        return 'danger';
      } else {
        return 'success';
      }
    },

    varicon(val) {
      if (val == 'A') {
        return 'AwardIcon';
      } else if (val == 'B') {
        return 'InfoIcon';
      } else if (val == 'C') {
        return 'ClockIcon';
      } else if (val == 'D') {
        return 'InfoIcon';
      } else if (val == 'E' || val == 'I' || val == 'J') {
        return 'UserIcon';
      } else if (val == 'PA') {
        return 'GridIcon';
      } else if (val == 'P') {
        return 'GridIcon';
      } else if (val == 'G') {
        return 'DollarSignIcon';
      } else if (val == 'H') {
        return 'EyeOffIcon';
      } else {
        return 'success';
      }
    },

    dateFormat(val) {
      return moment(String(val)).format('DD-MM-YYYY HH:mm');
    },

    statusChange(statusVal) {
      if (statusVal == 'D') {
        this.$swal({
          title: 'Are you sure you want to continue?',
          inputPlaceholder: 'Reason for rejecting the quote?',
          icon: 'warning',
          input: 'textarea',
          showCancelButton: true,
          allowOutsideClick: false,
          confirmButtonText: 'Yes, reject the quote!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            var value = {
              id: router.currentRoute.params.id,
              value: statusVal,
              notes: result.value,
            };
            this.updateStatus(value);
          }
        });
      } else if (statusVal == 'R') {
        this.$swal({
          title: 'Are you sure you want to continue?',
          text: 'The status of the quote will be changed to draft.',
          icon: 'warning',
          showCancelButton: true,
          allowOutsideClick: false,
          confirmButtonText: 'Yes, revise the quote!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            var value = {
              id: router.currentRoute.params.id,
              value: statusVal,
            };
            this.updateStatus(value);
          }
        });
      } else if (statusVal == 'P') {
        this.$swal({
          title: 'Are you sure you want to continue?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          allowOutsideClick: false,
          confirmButtonText: 'Yes, mark as loss!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            var value = {
              id: router.currentRoute.params.id,
              value: statusVal,
            };
            this.updateStatus(value);
          }
        });
      } else {
        var notemptyInventory =
          this.isEmptyOrSpaces(this.saleQuoteData.get_inventory.condition) ||
          this.isEmptyOrSpaces(this.saleQuoteData.get_inventory.mileageUnit) ||
          this.isEmptyOrSpaces(this.saleQuoteData.get_inventory.mileageValue) ||
          this.isEmptyOrSpaces(this.saleQuoteData.get_inventory.extColour) ||
          this.isEmptyOrSpaces(this.saleQuoteData.get_inventory.vinNo);

        var notemptyDeclearation =
          this.isEmptyOrSpaces(this.saleQuoteData.get_inventory.vd1) ||
          this.isEmptyOrSpaces(this.saleQuoteData.get_inventory.vd2) ||
          this.isEmptyOrSpaces(this.saleQuoteData.get_inventory.vd3) ||
          this.isEmptyOrSpaces(this.saleQuoteData.get_inventory.vd4) ||
          this.isEmptyOrSpaces(this.saleQuoteData.get_inventory.vd5) ||
          this.isEmptyOrSpaces(this.saleQuoteData.get_inventory.vd6) ||
          this.isEmptyOrSpaces(this.saleQuoteData.get_inventory.vd7);

        var notemptyCustomer =
          this.isEmptyOrSpaces(this.saleQuoteData.get_seller_customer.sellerType) ||
          this.isEmptyOrSpaces(this.saleQuoteData.get_seller_customer.phoneNumber) ||
          this.isEmptyOrSpaces(this.saleQuoteData.get_seller_customer.streetAddress) ||
          this.isEmptyOrSpaces(this.saleQuoteData.get_seller_customer.email) ||
          this.isEmptyOrSpaces(this.saleQuoteData.get_seller_customer.provence) ||
          this.isEmptyOrSpaces(this.saleQuoteData.get_seller_customer.postalCode) ||
          this.isEmptyOrSpaces(this.saleQuoteData.get_seller_customer.country);

        var notemptyQuote = this.isEmptyOrSpaces(this.saleQuoteData.vehicleTitleType) || this.isEmptyOrSpaces(this.saleQuoteData.completionDate) || this.isEmptyOrSpaces(this.saleQuoteData.quoteDate) || this.isEmptyOrSpaces(this.saleQuoteData.appraisedVehiclePrice);

        if (notemptyInventory || notemptyCustomer || notemptyQuote || notemptyDeclearation) {
          this.$swal({
            title: 'Fill in the missing fields!',
            text: 'You must fill in the mandatory fields before submitting it for manager approval.',
            icon: 'warning',
            showCancelButton: true,
            allowOutsideClick: false,
            confirmButtonText: 'Edit',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then((result) => {
            if (result.isConfirmed) {
              if (notemptyInventory) {
                router.push({
                  name: 'inventory-edit-sale',
                  params: {
                    id: this.saleQuoteData.inventoryId,
                    quoteid: this.saleQuoteData.id,
                  },
                });
              } else if (notemptyDeclearation) {
                router.push({
                  name: 'vehicle-sale-decleration',
                  params: {
                    id: this.saleQuoteData.inventoryId,
                    quoteid: this.saleQuoteData.id,
                  },
                });
              } else if (notemptyCustomer) {
                router.push({
                  name: 'customer-edit-sale',
                  params: {
                    id: this.saleQuoteData.purchaserCustomerId,
                    quoteid: this.saleQuoteData.id,
                  },
                });
              } else if (notemptyQuote) {
                router.push({
                  name: 'sales-validation-edit',
                  params: this.saleQuoteData.id,
                });
              } else {
              }

              //    :to="{ name: 'quote-edit', params: { id: $route.params.id } }
            }
          });
        } else {
          var buttonText = 'Yes, send to manager!';
          var textValue = "You won't be able to revert this!";

          if (statusVal == 'B') {
            buttonText = 'Yes, send to manager!';
          } else if (statusVal == 'C') {
            buttonText = 'Yes, approve the quote!';
          } else if (statusVal == 'D') {
            buttonText = 'Yes, reject the quote!';
          } else if (statusVal == 'E') {
            buttonText = 'Yes, send to client!';
          } else if (statusVal == 'PA') {
            buttonText = 'Yes, mark vehicle as sold';
          } else if (statusVal == 'P') {
            buttonText = 'Yes, mark as loss!';
          } else if (statusVal == 'R') {
            buttonText = 'Yes, revise the quote!';
            textValue = 'The status of the quote will be changed to draft.';
          } else if (statusVal == 'PMA') {
            buttonText = 'Yes, payment received!';
            textValue = 'The status of the quote will be changed to received';
          } else if (statusVal == 'PNMA') {
            buttonText = 'Yes, payment not received!';
            textValue = 'The status of the quote will be changed to not received.';
          } else if (statusVal == 'MarkComplete') {
            buttonText = 'Yes, Complete The Sale!';
            textValue = 'The status of the quote will be changed to closed.';
          } else if (statusVal == 'MarkCancel') {
            buttonText = 'Yes, Canceled The Sale!';
            textValue = 'The status of the quote will be changed to Canceled Sale.';
          }

          if (statusVal == 'D') {
          } else if (statusVal == 'PA') {
            this.$swal({
              title: 'Are you sure you want to continue?',
              text: textValue,
              icon: 'warning',
              showCancelButton: true,
              allowOutsideClick: false,
              confirmButtonText: buttonText,
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1',
              },
              buttonsStyling: false,
            }).then((result) => {
              if (result.isConfirmed) {
                var value = {
                  id: router.currentRoute.params.id,
                  value: statusVal,
                };

                if (this.saleQuoteData.get_inventory.received != 'yes') {
                  this.$swal({
                    icon: 'warning',
                    title: '',
                    text: 'The vehicle is not In Stock yet!',
                    customClass: {
                      confirmButton: 'btn btn-success',
                    },
                  });

                  router.push({
                    name: 'inventory-view',
                    params: { id: this.saleQuoteData.inventoryId },
                  });
                } else if (this.saleQuoteData.vehicleStock != 'yes') {
                  this.$swal({
                    icon: 'warning',
                    title: '',
                    text: "The vehicle In Stock but The Vehicle's is not Payment yet",
                    customClass: {
                      confirmButton: 'btn btn-success',
                    },
                  });

                  router.push({
                    name: 'quote-preview',
                    params: {
                      id: this.saleQuoteData.get_inventory.purchaseQuoteId,
                    },
                  });
                } else {
                  this.updateStatus(value);
                }
              }
            });
          } else {
            this.$swal({
              title: 'Are you sure you want to continue?',
              text: textValue,
              icon: 'warning',
              showCancelButton: true,
              allowOutsideClick: false,
              confirmButtonText: buttonText,
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1',
              },
              buttonsStyling: false,
            }).then((result) => {
              if (result.isConfirmed) {
                var value = {
                  id: router.currentRoute.params.id,
                  value: statusVal,
                };
                this.updateStatus(value);
              }
            });
          }
        }
      }
    },

    updateStatus(val) {
      this.formShow = true;
      val.email = this.saleQuoteData.sellerIdentification.email;
      val.updated_at = this.saleQuoteData.updated_at;
      var resultText = 'Your quote has been sent to manager';
      if (val.value == 'B') {
        resultText = 'Your quote has been sent to manager';
      } else if (val.value == 'C') {
        resultText = 'The quote has been approved';
      } else if (val.value == 'D') {
        resultText = 'The quote has been rejected';
      } else if (val.value == 'E') {
        resultText = 'The quote has been sent to client';
      } else if (val.value == 'PA') {
        resultText = 'The Vehicle marked as sold';
      } else if (val.value == 'P') {
        resultText = 'The Quote has been marked as loss';
      } else if (val.value == 'R') {
        resultText = 'The Quote has been changed as draft';
      } else if (val.value == 'PMA') {
        resultText = 'The Quote marked as payment received';
      } else if (val.value == 'PNMA') {
        resultText = 'The Quote marked as payment not received';
      } else if (val.value == 'MarkComplete') {
        resultText = 'The Quote marked as win-closed';
      }

      store
        .dispatch('sales/updateQuoteStatus', val)
        .then((response) => {
          if (response.data != 'alreadyUpdated') {
            this.formShow = false;

            if (response.data.return == false || response.data.return == 'consignment' ? false : true) {
              this.$swal({
                icon: 'success',
                title: 'Successful!',
                text: resultText,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              });

              if (this.where == 'sales-preview') {
                this.getByIdQuote('getPreviewQuoteById');
              } else {
                this.getByIdQuote('getArchivePreviewQuoteById');
              }
            } else {
              if (response.data.return == false) {
                this.$swal({
                  icon: 'warning',
                  title: '',
                  text: 'The vehicle is not In Stock yet!',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                });
              } else {
                this.$swal({
                  icon: 'warning',
                  title: '',
                  text: 'The consignment vehicle does not have a Completed Purchase Quote!',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                });
              }

              router.push({ name: 'quote-list' });
            }
          } else {
            this.formShow = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Please refresh the page.',
                text: '⚠️ Data has been changed during operations. ',
                icon: 'RefreshCwIcon',
                variant: 'warning',
              },
            });
          }
        })
        .catch((error) => {
          this.formShow = false;
          this.$swal({
            icon: 'error',
            title: 'Something went wrong!',
            text: 'Please try again or report an issue to support',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          });
        });
    },

    imageClick(url) {
      this.$swal({
        imageUrl: url,
        imageWidth: 1000,
        imageHeight: 600,
        showConfirmButton: false,
        width: '1000px',
      });
    },

    goToVehicle(val) {
      router.push({ name: 'inventory-view', params: { id: val } });
    },

    pdfDownload(val) {
      this.download = true;

      var link = 'pdfDownload';
      if (this.where == 'sales-preview') {
        link = 'pdfDownload';
      } else {
        link = 'pdfArchiveDownload';
      }

      store.dispatch('sales/' + link, val).then((response) => {
        if (response.status == 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          if (this.where == 'sales-preview') {
            link.setAttribute('download', 'Quote_' + Number(val) + '.pdf');
          } else {
            link.setAttribute('download', 'Archive_Quote_' + Number(val) + '.pdf');
          }
          document.body.appendChild(link);
          link.click();

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successful',
              text: '✔️ Quote pdf is created!',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          });
          this.download = false;
        }
      });
    },

    getByIdQuote(val) {
      this.formShow = true;

      if (router.currentRoute.params.id) {
        store
          .dispatch('sales/' + val, { id: router.currentRoute.params.id })
          .then((response) => {
            if (this.where == 'archive-sales-preview') {
              response.data.get_inventory = response.data.get_archive_inventory;
            }

            this.msrpOptions = response.data.get_msrp_options;
            response.data.msrpOptions = this.msrpOptions;
            this.accessioreOption = response.data.get_accessiore_options;
            response.data.accessioreOptions = this.accessioreOption;
            response.data.inventoryGallery = [];

            //   this.saleQuoteData = this.saleQuoteDataModel;
            response.data.get_images.forEach((element) => {
              response.data.inventoryGallery.push(element.name);
            });

            response.data.priceCalculationValues = [];
            response.data.priceCalculationValues.saleOptionAmount = 0;
            response.data.get_additional.forEach((element) => {
              response.data.priceCalculationValues.push({
                pcOptions: element.title,
                pcPrice: element.price,
                pcGst: element.gst,
                pcPst: element.pst,
                pcGstAmount: (element.price / 100) * element.gst,
                pcPstAmount: (element.price / 100) * element.pst,
                pcSubTotal: element.price + (element.price / 100) * element.gst + (element.price / 100) * element.pst,
              });

              response.data.priceCalculationValues.saleOptionAmount += Number(element.price) + (element.price / 100) * element.gst + (element.price / 100) * element.pst;
            });

            response.data.lienHolder = {
              sellerType: null,
              phoneNumber: null,
              firstName: null,
              lastName: null,
              email: null,

              unitNumber: null,
              provence: null,
              streetAddress: null,
              city: null,
              postalCode: null,
              country: null,
              gst: null,
              pst: null,
              dealer: null,
              driverLicenseNumber: null,
              otherIdNumber: null,
              dateOfBirth: null,
              contactPerson: null,
              contactInfo: null,
            };
            response.data.sellerIdentification = {
              sellerType: null,
              phoneNumber: null,
              firstName: null,
              lastName: null,
              email: null,
              unitNumber: null,
              provence: null,
              streetAddress: null,
              city: null,
              postalCode: null,
              country: null,
              gst: null,
              pst: null,
              dealer: null,
              driverLicenseNumber: null,
              otherIdNumber: null,
              dateOfBirth: null,
              contactPerson: null,
              contactInfo: null,
            };

            // if (response.data.coPurchaser != null) {
            //   response.data.coPurchaser.forEach((element, index) => {
            //     this.saleQuoteData.coPurchaser[index] = {
            //       sellerType: null,
            //       phoneNumber: null,
            //       firstName: null,
            //       lastName: null,
            //       email: null,
            //       unitNumber: null,
            //       provence: null,
            //       streetAddress: null,
            //       city: null,
            //       postalCode: null,
            //       country: null,
            //       gst: null,
            //       pst: null,
            //       dealer: null,
            //       driverLicenseNumber: null,
            //       otherIdNumber: null,
            //       dateOfBirth: null,
            //       contactPerson: null,
            //       contactInfo: null,
            //     };
            //   });
            // }

            this.saleQuoteData = response.data;
            this.notApprove = this.saleQuoteData.notes;

            this.saleQuoteData.vehicleDeclearation = [response.data.vd1, response.data.vd2, response.data.vd3, response.data.vd4, response.data.vd5, response.data.vd6, response.data.vd7];
            this.saleQuoteData.vehicleDeclearationText = response.data.vd1Text;
            this.saleQuoteData.lienPayout = {
              beforeTax: response.data.beforeTax,
              unitlDate: response.data.unitlDate,
              gstRate: response.data.gstRate,
              gstAmount: null,
              totalAmount: null,
            };

            if (response.data.get_lien_customer == null) {
              this.saleQuoteData.lienHolder = {
                sellerType: null,
                phoneNumber: null,
                firstName: null,
                lastName: null,
                email: null,

                unitNumber: null,
                provence: null,
                streetAddress: null,
                city: null,
                postalCode: null,
                country: null,
                gst: null,
                pst: null,
                dealer: null,
                driverLicenseNumber: null,
                otherIdNumber: null,
                dateOfBirth: null,
                contactPerson: null,
                contactInfo: null,
              };
            } else {
              //this.lienCustomerSelect(response.data.get_lien_customer.id);
              this.saleQuoteData.lienHolder.sellerType = response.data.get_lien_customer.sellerType;
              this.saleQuoteData.lienHolder.phoneNumber = response.data.get_lien_customer.phoneNumber;
              this.saleQuoteData.lienHolder.firstName = response.data.get_lien_customer.firstName;
              this.saleQuoteData.lienHolder.lastName = response.data.get_lien_customer.lastName;
              this.saleQuoteData.lienHolder.email = response.data.get_lien_customer.email;

              this.saleQuoteData.lienHolder.unitNumber = response.data.get_lien_customer.unitNumber;
              this.saleQuoteData.lienHolder.provence = response.data.get_lien_customer.provence;
              this.saleQuoteData.lienHolder.streetAddress = response.data.get_lien_customer.streetAddress;
              this.saleQuoteData.lienHolder.city = response.data.get_lien_customer.city;
              this.saleQuoteData.lienHolder.postalCode = response.data.get_lien_customer.postalCode;
              this.saleQuoteData.lienHolder.country = response.data.get_lien_customer.country;
              this.saleQuoteData.lienHolder.gst = response.data.get_lien_customer.gst;
              this.saleQuoteData.lienHolder.pst = response.data.get_lien_customer.pst;
              this.saleQuoteData.lienHolder.dealer = response.data.get_lien_customer.dealer;
              this.saleQuoteData.lienHolder.customerId = response.data.get_lien_customer.id;
              this.saleQuoteData.lienHolder.contactInfo = response.data.get_lien_customer.contactInfo;
              this.saleQuoteData.lienHolder.contactPerson = response.data.get_lien_customer.contactPerson;

              this.saleQuoteData.lienHolder.driverLicenseNumber = response.data.get_lien_customer.driverLicenseNumber;
              this.saleQuoteData.lienHolder.firstotherIdNumberName = response.data.get_lien_customer.firstotherIdNumberName;
              this.saleQuoteData.lienHolder.dateOfBirth = response.data.get_lien_customer.dateOfBirth;
            }

            if (response.data.get_seller_customer == null) {
              this.saleQuoteData.sellerIdentification = {
                sellerType: null,
                phoneNumber: null,
                firstName: null,
                lastName: null,
                email: null,

                unitNumber: null,
                provence: null,
                streetAddress: null,
                city: null,
                postalCode: null,
                country: null,
                gst: null,
                pst: null,
                dealer: null,
                driverLicenseNumber: null,
                otherIdNumber: null,
                dateOfBirth: null,
                contactPerson: null,
                contactInfo: null,
              };
            } else {
              //   this.sellerCustomerSelect(response.data.get_seller_customer.id);
              this.saleQuoteData.sellerIdentification.sellerType = Number(response.data.get_seller_customer.sellerType);
              this.saleQuoteData.sellerIdentification.phoneNumber = response.data.get_seller_customer.phoneNumber;
              this.saleQuoteData.sellerIdentification.firstName = response.data.get_seller_customer.firstName;
              this.saleQuoteData.sellerIdentification.lastName = response.data.get_seller_customer.lastName;
              this.saleQuoteData.sellerIdentification.email = response.data.get_seller_customer.email;

              this.saleQuoteData.sellerIdentification.unitNumber = response.data.get_seller_customer.unitNumber;
              this.saleQuoteData.sellerIdentification.provence = response.data.get_seller_customer.provence;
              this.saleQuoteData.sellerIdentification.streetAddress = response.data.get_seller_customer.streetAddress;
              this.saleQuoteData.sellerIdentification.city = response.data.get_seller_customer.city;
              this.saleQuoteData.sellerIdentification.postalCode = response.data.get_seller_customer.postalCode;
              this.saleQuoteData.sellerIdentification.country = response.data.get_seller_customer.country;
              this.saleQuoteData.sellerIdentification.gst = response.data.get_seller_customer.gst;
              this.saleQuoteData.sellerIdentification.pst = response.data.get_seller_customer.pst;
              this.saleQuoteData.sellerIdentification.dealer = response.data.get_seller_customer.dealer;
              this.saleQuoteData.sellerIdentification.customerId = response.data.get_seller_customer.id;

              this.saleQuoteData.sellerIdentification.driverLicenseNumber = response.data.get_seller_customer.driverLicenseNumber;
              this.saleQuoteData.sellerIdentification.otherIdNumber = response.data.get_seller_customer.otherIdNumber;
              this.saleQuoteData.sellerIdentification.dateOfBirth = response.data.get_seller_customer.dateOfBirth;
              this.saleQuoteData.sellerIdentification.contactPerson = response.data.get_seller_customer.contactPerson;
              this.saleQuoteData.sellerIdentification.contactInfo = response.data.get_seller_customer.contactInfo;
            }

            if (response.data.coPurchaser[0] == null) {
              this.saleQuoteData.coPurchaser = [];
            } else {
              response.data.coPurchaser.forEach((element, index) => {
                //   this.saleQuoteData.coPurchaser.push(element);

                this.saleQuoteData.coPurchaser[index].sellerType = element.sellerType;
                this.saleQuoteData.coPurchaser[index].phoneNumber = element.phoneNumber;
                this.saleQuoteData.coPurchaser[index].firstName = element.firstName;
                this.saleQuoteData.coPurchaser[index].lastName = element.lastName;
                this.saleQuoteData.coPurchaser[index].email = element.email;

                this.saleQuoteData.coPurchaser[index].unitNumber = element.unitNumber;
                this.saleQuoteData.coPurchaser[index].provence = element.provence;
                this.saleQuoteData.coPurchaser[index].streetAddress = element.streetAddress;
                this.saleQuoteData.coPurchaser[index].city = element.city;
                this.saleQuoteData.coPurchaser[index].postalCode = element.postalCode;
                this.saleQuoteData.coPurchaser[index].country = element.country;
                this.saleQuoteData.coPurchaser[index].gst = element.gst;
                this.saleQuoteData.coPurchaser[index].pst = element.pst;
                this.saleQuoteData.coPurchaser[index].dealer = element.dealer;

                this.saleQuoteData.coPurchaser[index].driverLicenseNumber = element.driverLicenseNumber;
                this.saleQuoteData.coPurchaser[index].otherIdNumber = element.otherIdNumber;
                this.saleQuoteData.coPurchaser[index].dateOfBirth = element.dateOfBirth;
                this.saleQuoteData.coPurchaser[index].contactPerson = element.contactPerson;
                this.saleQuoteData.coPurchaser[index].contactInfo = element.contactInfo;

                this.saleQuoteData.coPurchaser[index].customerId = element.id;

                // element.customerId = element.id;
                //response.data.coPurchaser.push(element);
              });
            }

            this.lessTrade = 0;
            this.saleQuoteData.trades.forEach((trade) => {
              // if (this.saleQuoteData.payTradeCredit == 0) {
              //   this.lessTrade += trade.appraisedVehiclePrice;
              // } else {
              //   this.lessTrade = response.data.balanceDeduction + response.data.payTradeCredit;
              // }
              this.lessTrade += trade.creditDue;
            });

            this.files = response.data.get_files;
            this.formShow = false;
          })
          .catch((error) => {
            console.log(error);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong',
                text: 'Please try again or report an issue to support',
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            });
            router.push({ name: 'sales-list' });
          });
      } else {
        this.formShow = false;
      }
    },
  },

  computed: {
    priceDifference() {
      if (this.customerTradeEquity <= 0) {
        if (Number((this.saleQuoteData.sellingPrice ?? 0) - (this.lessTrade ?? 0)) < 0) {
          return 0;
        } else {
          return Number((this.saleQuoteData.sellingPrice ?? 0) - (this.lessTrade ?? 0));
        }
      } else {
        return 0;
      }
    },

    nameState() {
      return this.modalFileName != null && this.modalFileName != '' ? true : false;
    },

    fileState() {
      return this.modalFile != null && this.modalFile != '' ? true : false;
    },

    sumBeforeTax() {
      // var total = 0;

      // this.saleQuoteData.trades.forEach((element) => {
      //   total += Number(element.beforeTax);
      // });

      // return Number(total);

      var total = 0;
      if (this.saleQuoteData.trades != undefined) {
        this.saleQuoteData.trades.forEach((element) => {
          total += Number(element.beforeTax);
        });
      }

      if (this.saleQuoteData.winTrades != undefined) {
        this.saleQuoteData.winTrades.forEach((element) => {
          total += Number(element.beforeTax);
        });
      }

      return Number(total);
    },

    saleBalanceDue() {
      var total = 0;
      if (this.sumBeforeTax > 0) {
        //VERGİ VARSA

        total = Number(
          Number(this.totalAdditionalOptions) + Number(this.sumBeforeTax) - Number(this.saleQuoteData.lessAdditionalCredit ? this.saleQuoteData.lessAdditionalCredit : 0) - Number(this.saleQuoteData.lessDepositPayable ? this.saleQuoteData.lessDepositPayable : 0) + Number(this.totalVehicleSaleAmount)
        );
      } else {
        //VERGİ YOKSA

        if (this.lessTrade < this.saleQuoteData.sellingPrice) {
          total = Number(this.totalVehicleSaleAmount + this.totalAdditionalOptions - Number(this.saleQuoteData.lessAdditionalCredit ? this.saleQuoteData.lessAdditionalCredit : 0) - Number(this.saleQuoteData.lessDepositPayable ? this.saleQuoteData.lessDepositPayable : 0));
        } else {
          total = Number(Number(this.saleQuoteData.sellingPrice) - Number(this.lessTrade) + Number(this.totalAdditionalOptions) + Number(this.saleQuoteData.deductionCredit));
        }
      }

      this.saleQuoteData.balanceDue = total;

      return total;
    },

    totalVehicleSaleAmount() {
      return Number(this.priceDifference + this.gstAmountOnVehicle + this.pstAmountOnVehicle);
    },

    totalAdditionalOptions() {
      return Number(this.additionaloptionsPrice - (this.saleQuoteData.deductionCredit ? this.saleQuoteData.deductionCredit : 0) + this.gstAdditionalOptions + this.pstAdditionalOptions);
    },

    customerTradeEquity() {
      if (this.lessTrade > this.saleQuoteData.sellingPrice) {
        return this.lessTrade - this.saleQuoteData.sellingPrice - this.saleQuoteData.deductionCredit - this.saleQuoteData.lessAdditionalCredit;
      } else {
        this.saleQuoteData.deductionCredit = null;
        this.saleQuoteData.lessAdditionalCredit = null;
        return 0;
      }
    },

    gstAmountOnVehicle() {
      if (this.priceDifference == 0) {
        return 0;
      } else if (this.saleQuoteData.gstRateVehicle == null || this.saleQuoteData.gstRateVehicle == '') {
        return 0;
      } else {
        return Number((this.priceDifference / 100) * this.saleQuoteData.gstRateVehicle);
      }
    },

    pstAmountOnVehicle() {
      if (this.priceDifference == 0) {
        return 0;
      } else if (this.saleQuoteData.pstRateVehicle == null || this.saleQuoteData.pstRateVehicle == '') {
        return 0;
      } else {
        return Number((this.priceDifference / 100) * this.saleQuoteData.pstRateVehicle);
      }
    },

    additionaloptionsPrice() {
      var total = 0;
      this.saleQuoteData.priceCalculationValues.forEach((element) => {
        total = Number(total) + Number(element.pcPrice);
      });

      return total;
    },

    gstAdditionalOptions() {
      if (this.lessTrade > this.saleQuoteData.sellingPrice) {
        var rate = 100;
        var total = 0;

        this.saleQuoteData.priceCalculationValues.forEach((element) => {
          if (element.pcGst < rate) {
            rate = element.pcGst;
          }
        });

        total = ((this.additionaloptionsPrice - this.saleQuoteData.deductionCredit) * rate) / 100;

        return total;
      } else {
        var rate = 100;
        var total = 0;

        this.saleQuoteData.priceCalculationValues.forEach((element) => {
          if (element.pcGst < rate) {
            rate = element.pcGst;
          }
        });

        this.saleQuoteData.priceCalculationValues.forEach((element) => {
          total = Number(total) + Number((element.pcPrice * rate) / 100);
        });

        return total;
      }
    },

    pstAdditionalOptions() {
      if (this.lessTrade > this.saleQuoteData.sellingPrice) {
        var rate = 100;
        var total = 0;

        this.saleQuoteData.priceCalculationValues.forEach((element) => {
          if (element.pcPst < rate) {
            rate = element.pcPst;
          }
        });

        total = ((this.additionaloptionsPrice - this.saleQuoteData.deductionCredit) * rate) / 100;

        return total;
      } else {
        var rate = 100;
        var total = 0;

        this.saleQuoteData.priceCalculationValues.forEach((element) => {
          if (element.pcPst < rate) {
            rate = element.pcPst;
          }
        });

        this.saleQuoteData.priceCalculationValues.forEach((element) => {
          total = Number(total) + Number((element.pcPrice * rate) / 100);
        });

        return total;
      }
    },

    contractAmount() {
      return (
        Number(this.additionaloptionsPrice) +
        Number(this.priceDifference) +
        Number(this.gstAmountOnVehicle) +
        //Number(this.customerTradeEquity) +
        Number(this.pstAmountOnVehicle) +
        Number(this.sumBeforeTax) -
        (this.saleQuoteData.deductionCredit ? this.saleQuoteData.deductionCredit : 0) +
        Number(this.gstAdditionalOptions) +
        Number(this.pstAdditionalOptions)
      );
    },
  },
};
</script>

<style>
.textlongvalue {
  line-height: 1.84;
}

.title {
  font-size: 12px;
}

.textproduct {
  font-size: 12px;
}

.noborderright {
  border-right: hidden !important;
}

.valueinput {
  font-size: 16px;
}
</style>
